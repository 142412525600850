import React from 'react';
import cls from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// components
import Button from '../Button';

// effects
import { useWindowResizeListener } from '../../effects/App.effects';

// providers
import { useApp } from '../../providers/App';

const HomeVariants = () => {
  const { formatMessage } = useIntl();
  const { siteLang } = useApp();

  const { width } = useWindowResizeListener();

  return (
    <div className="home-variants-content">
      <div className="home-variants-container">
        <div className="home-variants-title">
          {width < 576 ? (
            <>
              <h2>{formatMessage({ id: 'homeTitle' })}</h2>
              <h2>{formatMessage({ id: 'homeTitle2' })}</h2>
            </>
          ) : (
            <h2>
              {formatMessage({ id: 'homeTitle' })}{' '}
              {formatMessage({ id: 'homeTitle2' })}
            </h2>
          )}
        </div>
        <div className="home-variants-details">
          <div className={cls('home-variants-infos', { reverse: width < 768 })}>
            <Link to="/contact">
              <div className="button-home-variants">
                <Button
                  type="solidLarge"
                  fullWidth
                  size={siteLang !== 'pt-BR' ? 'medium' : 'large'}
                >
                  {formatMessage({ id: 'letsTalk' })}
                </Button>
              </div>
            </Link>
            <div className="home-variants-subtitle">
              <span>
                {formatMessage({ id: 'homeSubTitle' })}
                {formatMessage({ id: 'homeSubTitle2' })}
                {formatMessage({ id: 'homeSubTitle3' })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeVariants.propTypes = {};

export default HomeVariants;
