import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { matchPath } from 'react-router-dom';
import * as MaterialIcons from '@material-ui/icons';

const Button = ({
  children,
  className,
  icon,
  onClick,
  style,
  testID,
  type,
  loading,
  size,
  fullWidth,
}) => {
  const Icon = React.useMemo(() => MaterialIcons[icon], [icon]);

  const matchCase = matchPath('/case/123', {
    path: '/case/:id',
  });

  const matchArticle = matchPath('/article/123', {
    path: '/article/:id',
  });

  const headerOpacity =
    matchCase !== null || matchArticle !== null ? 'inside' : 'other';

  return (
    <button
      data-testid={`${testID}_Button`}
      className={cls('button', className, type, headerOpacity, size, {
        fullWidth,
        loading,
      })}
      onClick={onClick}
      style={style}
    >
      {Icon ? <Icon fontSize="inherit" /> : children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'large']).isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  testID: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  type: PropTypes.oneOf([
    'outlinedMedium',
    'outlinedSmall',
    'outlinedLarge',
    'solidMedium',
    'solidSmall',
    'solidLarge',
    'headerButton',
    'notFound',

    // TODO: refactor and remove the above types
    'outlined',
    'rounded',
    'solid',
  ]),
  headerOpacity: PropTypes.oneOf(['inside', 'other']),
  onClick: PropTypes.func,
};

Button.defaultProps = {
  size: 'medium',
  type: 'outlinedMedium',
  testID: 'Button',
};
export default Button;
