import { useEffect, useState } from 'react';

export const useWindowResizeListener = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const onResize = event => {
    setWidth(event.currentTarget.innerWidth);
  };
  useWindowEventListener('resize', onResize);
  return { width };
};

export const useWindowScrollListener = () => {
  const [scroll, setScroll] = useState();
  const onScroll = event => {
    setScroll(event.currentTarget.scrollY > 0);
  };
  useWindowEventListener('scroll', onScroll);
  return { scroll };
};

const useWindowEventListener = (event, callback) =>
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => {
      window.removeEventListener(event, callback);
    };
  }, [event, callback]);

export const useWindowResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return width;
};
