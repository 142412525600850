import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';

import { useWindowResize } from '../../effects/App.effects';
import { ArticlesActions } from '../../store';
import { useApp } from '../../providers';

import { ReactComponent as SvgFacebook } from '../../assets/images/icons/ic_facebook.svg';
import { ReactComponent as SvgLinkedIn } from '../../assets/images/icons/ic_linkedin.svg';
import { ReactComponent as SvgInstagram } from '../../assets/images/icon_instagram.svg';
import { ReactComponent as SvgYoutube } from '../../assets/images/icon_youtube.svg';

const ContactConfirmation = ({ articles }) => {
  const { api, siteLang: language } = useApp();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const width = useWindowResize();

  React.useEffect(() => {
    dispatch(ArticlesActions.getArticles(api, { language }));
  }, [api, dispatch, language]);

  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
      <div className="contact-confirmation">
        <div className="contact-confirmation-content">
          <h1>{formatMessage({ id: 'contactConfirmationTitle' })}</h1>
          <h6>{formatMessage({ id: 'contactConfirmationSubtitle' })}</h6>
          <p>{formatMessage({ id: 'contactConfirmationParagraph' })}</p>
          <div className="our-articles">
            <div className="our-articles-content">
              <Grid container spacing={width < 576 ? 0 : 4}>
                {articles?.slice(-3).map((item, i) => (
                  <Grid
                    className="our-articles-content-grid"
                    key={`article_item_${i}`}
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <NavLink
                      to={{
                        pathname: `/article/${item.id}`,
                        state: { item: item },
                      }}
                    >
                      <div className="articles-card">
                        <img src={item?.cover?.formats?.small?.url} alt="" />
                      </div>
                      <div>
                        <h3>{item.title}</h3>
                        <p>{item.subtitle}</p>
                      </div>
                    </NavLink>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
          <div className="contact-confirmation-content-icons">
            <div className="contact-confirmation-content-icons-content">
              <div className="sitemap-icons">
                <a
                  href="https://www.linkedin.com/company/bycoders-tecnologia/"
                  target="_black"
                >
                  <SvgLinkedIn className="icon-svg" />
                </a>
              </div>
              <div className="sitemap-icons">
                <a href="https://www.facebook.com/bycodersTec/" target="_black">
                  <SvgFacebook className="icon-svg" />
                </a>
              </div>
              <div className="sitemap-icons">
                <a href="https://www.instagram.com/bycoders_/" target="_black">
                  <SvgInstagram className="icon-svg" />
                </a>
              </div>
              <div className="sitemap-icons">
                <a
                  href="https://www.youtube.com/channel/UCJoRus1OxyAXMHDTeByRl5w/featured"
                  target="_black"
                >
                  <SvgYoutube className="icon-svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );
};

ContactConfirmation.propTypes = {
  articles: PropTypes.array,
};

const mapStateToProps = state => ({
  articles: state.articles.data,
});

export default connect(mapStateToProps)(ContactConfirmation);
