import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import CarouselSlider from '../CarouselSlider';
import Hover from '../Hover';
import { Link } from 'react-router-dom';

import { useWindowResize } from '../../effects/App.effects';
import { assetUrl } from '../../helpers/Strapi.helper';

const Articles = ({ data }) => {
  const width = useWindowResize();
  const { formatMessage } = useIntl();
  const [first, second, third, fourth, fifth] = data?.slice(0, 5) || [];

  return (
    <>
      {width < 768 ? (
        <CarouselSlider
          data={data}
          subtitle="read ours"
          title="articles"
          type="articlesHome"
        />
      ) : (
        <div className="articles-lg">
          <div className="articles-lg-content">
            <div className="articles-lg-content-header">
              <p>bycoders_</p>
              <h3>{formatMessage({ id: 'articlesPageTitle' })}</h3>
            </div>
            <div>
              <div className="images-header">
                <div className="images">
                  <Link
                    to={{
                      pathname: `/article/${first?.id}`,
                    }}
                  >
                    <img src={assetUrl(first?.cover, 'large')} alt="" />
                    <div className="hover">
                      <Hover
                        title={first?.title}
                        subtitle={first?.subtitle}
                        type="articles"
                      />
                    </div>
                  </Link>
                </div>
                <div className="images">
                  <Link
                    to={{
                      pathname: `/article/${second?.id}`,
                    }}
                  >
                    <img src={assetUrl(second?.cover, 'large')} alt="" />
                    <div className="hover">
                      <Hover
                        title={second?.title}
                        subtitle={second?.subtitle}
                        type="articles"
                      />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="images-content">
                <div className="images-content-sm">
                  <div className="images-sm">
                    <div className="images-sm-space" />
                    <div className="images-sm-container">
                      <div className="images-sm-container-imgs">
                        <Link
                          to={{
                            pathname: `/article/${fifth?.id}`,
                          }}
                        >
                          <img src={assetUrl(fifth?.cover, 'large')} alt="" />
                          <div className="hover">
                            <Hover
                              title={fifth?.title}
                              subtitle={fifth?.subtitle}
                              type="articlesSmall"
                            />
                          </div>
                        </Link>
                      </div>
                      <div className="images-sm-container-imgs">
                        <Link
                          to={{
                            pathname: `/article/${fourth?.id}`,
                          }}
                        >
                          <img
                            src={assetUrl(fourth?.cover, 'large')}
                            alt=""
                            className="reverse"
                          />
                          <div className="hover">
                            <Hover
                              title={fourth?.title}
                              subtitle={fourth?.subtitle}
                              type="articlesSmall"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="images">
                  <Link
                    to={{
                      pathname: `/article/${third?.id}`,
                    }}
                  >
                    <img src={assetUrl(third?.cover, 'large')} alt="" />
                    <div className="hover">
                      <Hover
                        title={third?.title}
                        subtitle={third?.subtitle}
                        type="articles"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

Articles.propTypes = {
  data: PropTypes.array,
};

Articles.defaultProps = {
  data: [],
};

export default Articles;
