import React from 'react';
import PropTypes from 'prop-types';
import { Button, Layout, SiteMap } from '../../components';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Markdown from 'markdown-to-jsx';
import ScrollAnimation from 'react-animate-on-scroll';
import { useApp } from '../../providers';
import { pageView } from '../../utils/Mautic.util';
import { useIntl } from 'react-intl';

const useVacancyFetcher = id => {
  const { api } = useApp();
  React.useEffect(() => {
    pageView();
  }, []);

  const [data, setData] = React.useState({
    case: null,
    error: null,
    isFetching: true,
  });

  React.useEffect(() => {
    if (!id) return;

    const fetchVacancy = async id => {
      try {
        const response = await api.getJobVacancy(id);
        setData({ vacancy: response.data, isFetching: false });
      } catch (error) {
        setData({
          error: error?.message || 'Falha ao consultar a vaga.',
          isFetching: false,
        });
      }
    };

    fetchVacancy(id);
  }, [api, id]);

  return data;
};

const Vacancy = () => {
  const history = useHistory();
  const params = useParams();
  const { vacancy: data } = useVacancyFetcher(params?.id);
  const { formatMessage } = useIntl();
  const { siteLang: language } = useApp();

  const auxLanguage = language === 'pt-BR' ? 'pt-BR' : 'en';

  React.useEffect(() => {
    if (data?.locale === auxLanguage) return;

    const _vacancy = data?.localizations[0].id;

    if (_vacancy !== undefined) {
      history.replace(`/careers/vacancy/${_vacancy}`);
    }
  }, [data, history, auxLanguage]);

  return (
    <Layout className="vacancy" showFooter showHeader testID="Vacancy">
      <div className="vacancy-header">
        <div className="vacancy-description">
          <div className="description">
            <div className="vacancy-header-top">
              <div className="header-title">
                <span>{data?.is_remote ? 'Remoto' : data?.location}</span>
                <h2>{data?.name}</h2>
                <div className="skills">
                  {data?.skills.map((skill, i) => (
                    <span key={i}>{skill.name}</span>
                  ))}
                </div>
              </div>
            </div>
            <div className="description-content">
              {data?.description && (
                <ScrollAnimation
                  animateOnce={true}
                  animateIn="fadeIn"
                  delay={300}
                >
                  <div className="header-paragraph-first">
                    <Markdown>{data.description}</Markdown>
                  </div>
                </ScrollAnimation>
              )}

              <br />
              <br />
              {data && (
                <Button
                  type="solidLarge"
                  fullWidth
                  size="large"
                  onClick={() => {
                    window.location.href = 'https://bycoders.inhire.app/vagas';
                  }}
                >
                  {formatMessage({ id: 'careerPageApplyVancancy' })}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <SiteMap />
    </Layout>
  );
};

Vacancy.propTypes = {
  careers: PropTypes.array,
};
const mapStateToProps = state => ({
  careers: state.careers.data,
});
export default connect(mapStateToProps)(Vacancy);
