import React from 'react';
import cls from 'classnames';
import Logo from '../../assets/images/logo_bycoders_.svg';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LanguageDropdown from '../LanguageDropdown';

const Footer = ({ testID }) => {
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div data-testid={`${testID}_Footer`} className={cls('content-footer')}>
        <Link to="/">
          <div>
            <img
              data-testid={`${testID}_Footer_Image`}
              src={Logo}
              alt="bycoders_"
            />
          </div>
        </Link>
        <div className={cls('wrapper')}>
          <span data-testid={`${testID}_Footer_Span_Wrapper`}>
            © {year} bycoders_
          </span>
          <div className="toggle-lang">
            <LanguageDropdown position="top" type="secondary" />
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  testID: PropTypes.string.isRequired,
};
Footer.defaultProps = {
  testID: 'Footer',
};
export default Footer;
