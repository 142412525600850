import React, { useEffect, useRef } from 'react';
import { ReactComponent as ArrowDown } from '../../../../assets/images/arrow-down.svg';
import PropTypes from 'prop-types';

const Header = ({ title, isHovered, isActive, toggleCard }) => {
  const headerRef = useRef();
  const underScoreTitleRef = useRef();
  const titleRef = useRef();
  const arrowRef = useRef();

  useEffect(() => {
    if (isHovered) {
      titleRef.current.classList.add('hover');
      underScoreTitleRef.current.classList.add('hover');
    } else {
      titleRef.current.classList.remove('hover');
      underScoreTitleRef.current.classList.remove('hover');
    }
  }, [isHovered]);

  useEffect(() => {
    if (isActive) {
      headerRef.current.classList.add('active');
      arrowRef.current.classList.add('active');
      titleRef.current.classList.add('active');
      underScoreTitleRef.current.classList.add(
        'service-card-title-underscore-active',
      );
    } else {
      headerRef.current.classList.remove('active');
      arrowRef.current.classList.remove('active');
      titleRef.current.classList.remove('active');
      underScoreTitleRef.current.classList.remove(
        'service-card-title-underscore-active',
      );
    }
  }, [isActive]);

  return (
    <div ref={headerRef} className="service-card-mobile-header">
      <div className="service-card-title-wrapper">
        <div ref={titleRef} className="service-card-mobile-title">
          {title}
        </div>

        <div
          ref={underScoreTitleRef}
          className="service-card-mobile-title-underscore"
        />
      </div>
      <div
        ref={arrowRef}
        onClick={e => {
          e.stopPropagation();
          toggleCard();
        }}
        className="service-card-mobile-header-arrow"
      >
        <ArrowDown />
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  isHovered: PropTypes.bool,
  isActive: PropTypes.bool,
  toggleCard: PropTypes.func,
};

export default Header;
