import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { StrapiHelper } from '../../helpers';

const Clients = ({ clients }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="clients">
      <div className="clients-header">
        <div className="clients-header-title">
          <h3>{formatMessage({ id: 'homeClientsTitle' })}</h3>
        </div>
        <div className="clients-header-subtitle">
          <span>
            {formatMessage({ id: 'homeClientsText' })}
            {formatMessage({ id: 'homeClientsText2' })}
          </span>
        </div>
      </div>

      <div className="clients-content">
        {clients?.map((client, i) => (
          <div key={`client_${i}`}>
            <img
              className="image"
              alt="logo"
              src={StrapiHelper.assetUrl(client.logo)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

Clients.propTypes = {
  clients: PropTypes.array,
};

export default Clients;
