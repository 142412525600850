import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import { SITE_URL } from '../../helpers/Strapi.helper';
import { ReactComponent as SvgFacebook } from '../../assets/images/icons/ic_facebook.svg';
import { ReactComponent as SvgLinkedIn } from '../../assets/images/icons/ic_linkedin.svg';
import { ReactComponent as SvgTwitter } from '../../assets/images/icons/ic_twitter.svg';

const SocialButtons = ({ size, testID, data }) => {
  return (
    <div className="social" data-testid={`${testID}_SocialButtons`}>
      <LinkedinShareButton url={SITE_URL} title={data.title.toUpperCase()}>
        <div className={cls('social-buttons', size)}>
          <SvgLinkedIn className="svg" />
        </div>
      </LinkedinShareButton>
      <FacebookShareButton quote={data.title} url={SITE_URL}>
        <div className={cls('social-buttons', size)}>
          <SvgFacebook className="svg" />
        </div>
      </FacebookShareButton>
      <TwitterShareButton url={SITE_URL} title={data.title.toUpperCase()}>
        <div className={cls('social-buttons', size)}>
          <SvgTwitter className="svg" />
        </div>
      </TwitterShareButton>
    </div>
  );
};

SocialButtons.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
  testID: PropTypes.string.isRequired,
  data: PropTypes.object,
};

SocialButtons.defaultProps = {
  size: 'medium',
  testID: 'SocialButtons',
};

export default SocialButtons;
