import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import ActiveContent from './ActiveContent';
import PropTypes from 'prop-types';

const ServiceCardDesktop = ({
  id,
  title,
  text,
  actionLink,
  activeByHover,
  isDefaultActive,
  img,
  clientimg,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(isDefaultActive);
  const serviceRef = useRef();
  const imgRef = useRef();

  const handleHover = () => {
    serviceRef.current.addEventListener('mouseenter', () => {
      setIsHovered(true);

      serviceRef.current.classList.add('service-card-container-active');

      imgRef.current && imgRef.current.classList.add('service-card-img-active');

      activeByHover && activateCard();
    });

    serviceRef.current.addEventListener('mouseleave', () => {
      setIsHovered(false);

      serviceRef.current.classList.remove('service-card-container-active');

      imgRef.current &&
        imgRef.current.classList.remove('service-card-img-active');

      activeByHover && disableCard();
    });
  };

  const activateCard = () => {
    serviceRef.current && serviceRef.current.classList.remove('pointer');
    setIsActive(true);
  };

  const disableCard = () => {
    serviceRef.current && serviceRef.current.classList.add('pointer');
    setIsActive(() => false);
  };

  useEffect(() => {
    handleHover();
  }, []);

  return (
    <div
      id={id}
      onClick={activateCard}
      ref={serviceRef}
      className="service-card-container pointer"
    >
      <Header
        activeByHover={activeByHover}
        title={title}
        isHovered={isHovered}
        isActive={isActive}
        disableCard={disableCard}
      />
      {!isActive &&
        React.cloneElement(img, {
          ref: imgRef,
          className: 'service-card-img',
        })}
      {isActive && (
        <ActiveContent
          text={text}
          actionLink={actionLink}
          clientimg={clientimg}
        />
      )}
    </div>
  );
};

ServiceCardDesktop.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  actionLink: PropTypes.string,
  activeByHover: PropTypes.bool,
  isDefaultActive: PropTypes.bool,
  img: PropTypes.node,
  clientimg: PropTypes.node,
};

export default ServiceCardDesktop;
