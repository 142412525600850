const getServiceRoute = () => {
  const storageServiceRoute = localStorage.getItem('serviceRoute');

  if (storageServiceRoute) {
    return storageServiceRoute;
  } else {
    const newServiceRoute = Math.random() >= 0.5 ? '/services' : '/services2';

    localStorage.setItem('serviceRoute', newServiceRoute);

    return newServiceRoute;
  }
};

export default getServiceRoute;
