import { createSlice } from '@reduxjs/toolkit';

const careersStore = createSlice({
  name: 'careersStore',
  initialState: {
    data: [],
    error: '',
    loading: true,
  },
  reducers: {
    getJobVacancies: state => ({
      ...state,
      loading: true,
    }),
    getJobVacanciesSuccess: (state, { payload }) => ({
      ...state,
      data: payload.jobVacancies,
      loading: false,
    }),
    getJobVacanciesFail: (state, { payload }) => ({
      ...state,
      error: payload.error,
      loading: false,
    }),
  },
});

export const careersActions = careersStore.actions;

export const careersReducer = careersStore.reducer;
