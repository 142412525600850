import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './LanguageDropdownItem.scss';

import { useApp } from '../../../providers/App';

const LanguageDropdownItem = ({ flag, label, action, setIsOpen }) => {
  const { setSiteLang } = useApp();
  const itemRef = useRef(null);

  useEffect(() => {
    itemRef.current.addEventListener('click', () => {
      setSiteLang(action);
      setIsOpen(false);
    });
  }, []);

  return (
    <div ref={itemRef} className="dropdown-item">
      <img className="dropdown-item-flag" src={flag} width={32} height={32} />
      <div className="dropdown-item-label">{label}</div>
    </div>
  );
};

LanguageDropdownItem.propTypes = {
  flag: PropTypes.string,
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func,
};

export default LanguageDropdownItem;
