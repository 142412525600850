import { createSlice } from '@reduxjs/toolkit';

import { uniqueById } from '../../utils/Filter.util';

const casesStore = createSlice({
  name: 'casesStore',
  initialState: {
    data: [],
    error: '',
    loading: true,
    loadingMore: false,
  },
  reducers: {
    getCases: state => ({
      ...state,
      loading: true,
    }),
    getCasesSuccess: (state, { payload }) => ({
      ...state,
      data: payload.cases,
      loading: false,
    }),
    getCasesFail: (state, { payload }) => ({
      ...state,
      error: payload.error,
      loading: false,
    }),

    getMoreCases: state => ({
      ...state,
      loadingMore: true,
    }),
    getMoreCasesSuccess: (state, { payload }) => ({
      ...state,
      data: [...(state.data || []), ...(payload.cases || [])].filter(
        uniqueById,
      ),
      loadingMore: false,
    }),
    getMoreCasesFail: (state, { payload }) => ({
      ...state,
      error: payload.error,
      loadingMore: false,
    }),
  },
});

export const casesActions = casesStore.actions;

export const casesReducer = casesStore.reducer;
