import { LOCALES } from '../I18n';
import { THEMES } from '../../themes';
import { getFromStorage } from '../../utils/localStorage';

const appInitialState = {
  themeName: getFromStorage('themeName') || THEMES.BASIC,
  siteLang: getFromStorage('siteLang') || LOCALES.ENGLISH,
  name: 'website bycoders_',
};

const appReducer = (state, action) => {
  switch (action.type) {
    case 'setTheme':
      return { ...state, themeName: action.themeName };
    case 'setLang':
      return { ...state, siteLang: action.siteLang };
    default:
      return appInitialState;
  }
};

export { appReducer, appInitialState };
