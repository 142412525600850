import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { AppProvider } from './providers';

import App from './App';

import configureStore from './store';

const { store, persistor } = configureStore();

const Root = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppProvider>
          <App />
        </AppProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

export default Root;
