import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Input = ({
  autoComplete,
  autoFocus,
  borderColor,
  error,
  label,
  name,
  checked,
  placeholder,
  required,
  type,
  value,
  onChange,
  testID,
}) => {
  const _testID = `${testID}_Input`;
  const common = {
    autoComplete: autoComplete || 'off',
    autoFocus,
    className: cls('input', { error: !!error }),
    placeholder,
    required,
    type,
    name,
    checked,
    value,
    onChange,
    'data-testid': _testID,
  };
  return (
    <div className="input-container" data-testid={`${_testID}_Container`}>
      {type === 'radio' ? (
        <div className="input-radio">
          <input {...common} />
          <span className="custom-radio"></span>
          <p>{label}</p>
        </div>
      ) : (
        <input {...common} style={{ borderColor }} />
      )}
      <span className="error" data-testid={`${_testID}_Error`}>
        {error}
      </span>
    </div>
  );
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  borderColor: PropTypes.string,
  checked: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  testID: PropTypes.string,
  type: PropTypes.oneOf(['number', 'text', 'email', 'radio']),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  testID: 'Input',
};

export default Input;
