import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const ActiveContent = ({ text, actionLink, clientimg }) => {
  const { formatMessage } = useIntl();
  const actionRef = useRef();
  const actionUnderscoreRef = useRef();

  useEffect(() => {
    actionRef.current.addEventListener('mouseenter', () => {
      actionRef.current.classList.add('hover');
      actionUnderscoreRef.current.classList.add('hover');
    });

    actionRef.current.addEventListener('mouseleave', () => {
      actionRef.current.classList.remove('hover');
      actionUnderscoreRef.current.classList.remove('hover');
    });
  }, []);

  return (
    <div className="service-card-active-container">
      <div className="service-card-active-text">{text}</div>
      <div className="service-card-active-img-action">
        <div className="service-card-active-img">{clientimg}</div>
        <div
          ref={actionRef}
          className="service-card-active-action"
          onClick={() => {
            window.open(actionLink, '_blank');
          }}
        >
          <div className="service-card-active-action-label">
            {formatMessage({ id: 'readMore' })}
          </div>
          <div
            ref={actionUnderscoreRef}
            className="service-card-active-action-underscore"
          />
        </div>
      </div>
    </div>
  );
};

ActiveContent.propTypes = {
  text: PropTypes.string,
  actionLink: PropTypes.string,
  clientimg: PropTypes.node,
};

export default ActiveContent;
