import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Header = ({ title, isHovered }) => {
  const headerRef = useRef();
  const underScoreTitleRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    if (isHovered) {
      titleRef.current.classList.add('hover');
      underScoreTitleRef.current.classList.add('hover');
    } else {
      titleRef.current.classList.remove('hover');
      underScoreTitleRef.current.classList.remove('hover');
    }
  }, [isHovered]);

  return (
    <div ref={headerRef} className="service-card-home-mobile-header">
      <div className="service-card-home-title-wrapper">
        <div ref={titleRef} className="service-card-home-mobile-title">
          {title}
        </div>

        <div
          ref={underScoreTitleRef}
          className="service-card-mobile-title-underscore"
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  isHovered: PropTypes.bool,
};

export default Header;
