import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import cls from 'classnames';
// components
import { Layout, SiteMap } from '../../components';
// effects
import { useWindowResizeListener } from '../../effects/App.effects';
// others
import SvgGreatPlace from '../../assets/images/icons/ic_selo-gptw.svg';
import { useApp } from '../../providers';
import { CareersActions } from '../../store';
import { pageView } from '../../utils/Mautic.util';
import CollapseList from '../../components/CollapseList';

import { Input } from '../../components';
import { KeyboardArrowRight } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

const Careers = ({ jobVacancies }) => {
  const [listFilteredVacancies, setlistFilteredVacancies] = useState([]);
  const [listSearchedVacancies, setlistSearchedVacancies] = useState([]);
  const [isFiltered, setFiltered] = useState(false);
  const [isSearched, setSearched] = useState(false);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { width } = useWindowResizeListener();
  const { api, siteLang: language } = useApp();

  React.useEffect(() => {
    pageView();
  }, []);
  React.useEffect(() => {
    dispatch(CareersActions.getJobVacancies(api, { language }));
    //setListVacancies(jobVacancies);
  }, [api, language]);

  function updateListVacancies(defaultList, text) {
    let filteredList = [];

    if (isFiltered) {
      defaultList.map(x => {
        listFilteredVacancies.map(y => {
          if (x.id === y.id) filteredList.push(x);
        });
      });
    } else filteredList = defaultList;

    if (text !== '') {
      filteredList = filteredList.filter(x => {
        return x.name.toUpperCase().indexOf(text.toUpperCase()) != -1;
      });
      setSearched(true);
    } else setSearched(false);

    setlistSearchedVacancies(filteredList);
  }

  return (
    <Layout className="careers" showFooter showHeader testID="Careers">
      <div className="careers-header">
        <div className="careers-header-top">
          <div className="header-title">
            <span>{formatMessage({ id: 'careerPageSubtitle' })}</span>
            <h2>{formatMessage({ id: 'careerPageTitle' })}</h2>
          </div>
        </div>

        <div className="careers-description">
          <div className="description">
            <div className="images">
              <iframe
                title="Vem sem um coder_!! Vem para bycoders_"
                src="https://www.youtube.com/embed/WhN6mKq_wG4"
                className="iframe"
              ></iframe>
            </div>
            <div className="description-content">
              <h3>{formatMessage({ id: 'careerPageWorkAnywhereTitle' })}</h3>
              <span>{formatMessage({ id: 'careerPageWorkAnywhere1' })}</span>
              <br />
              <br />
              <span>{formatMessage({ id: 'careerPageWorkAnywhere2' })}</span>
              <br />
              <br />
              <span>{formatMessage({ id: 'careerPageWorkAnywhere3' })}</span>
              <br />
              <br />
              <span>{formatMessage({ id: 'careerPageWorkAnywhere4' })}</span>
            </div>
          </div>

          <div className="description">
            <div
              className={cls('description-content', { reverse: width > 1365 })}
            >
              <h3 className={cls({ reverse: width > 1365 })}>
                {formatMessage({ id: 'careerPageOurTeamTitle' })}
              </h3>
              <span className={cls({ reverse: width > 1365 })}>
                {formatMessage({ id: 'careerPageOurTeam1' })}
              </span>
              <br />
              <span className={cls({ reverse: width > 1365 })}>
                {formatMessage({ id: 'careerPageOurTeam2' })}
              </span>
              <br />
              <span className={cls({ reverse: width > 1365 })}>
                {formatMessage({ id: 'careerPageOurTeam3' })}
              </span>
            </div>

            <div className="images-content">
              <div className={cls('images', { greatPlace: true })}>
                <img src={SvgGreatPlace} alt="" />
              </div>
            </div>
          </div>

          <div className="description mt-5">
            <div className="recruitmentMenu">
              <CollapseList
                defaultList={jobVacancies}
                setList={setlistFilteredVacancies}
                setFiltered={setFiltered}
              />
            </div>
            <div className="description-content">
              <h3>
                {formatMessage({ id: 'careerPageTitleOurOpportunities' })}
              </h3>

              <Input
                placeholder={formatMessage({
                  id: 'careerPageFilterEnterVancancy',
                })}
                type="text"
                onChange={e =>
                  updateListVacancies(jobVacancies, e.target.value)
                }
              />

              {isSearched
                ? listSearchedVacancies.map((vacancy, index) => (
                    <div className="card-vacancy" key={index}>
                      <div className="content">
                        <span>
                          {vacancy.is_remote ? 'Remoto' : vacancy.location}
                        </span>
                        <div className="vacancy fontsize28">{vacancy.name}</div>
                        <div className="skills">
                          {vacancy.skills.map((skill, i) => (
                            <span key={i}>{skill.name}</span>
                          ))}
                        </div>
                      </div>
                      <div className="icon">
                        <NavLink
                          to={{
                            pathname: `/careers/vacancy/${vacancy.id}`,
                          }}
                        >
                          <KeyboardArrowRight />
                        </NavLink>
                      </div>
                    </div>
                  ))
                : isFiltered
                ? listFilteredVacancies.map((vacancy, index) => (
                    <div className="card-vacancy" key={index}>
                      <div className="content">
                        <span>
                          {vacancy.is_remote ? 'Remoto' : vacancy.location}
                        </span>
                        <div className="vacancy fontsize28">{vacancy.name}</div>
                        <div className="skills">
                          {vacancy.skills.map((skill, i) => (
                            <span key={i}>{skill.name}</span>
                          ))}
                        </div>
                      </div>
                      <div className="icon">
                        <NavLink
                          to={{
                            pathname: `/careers/vacancy/${vacancy.id}`,
                          }}
                        >
                          <KeyboardArrowRight />
                        </NavLink>
                      </div>
                    </div>
                  ))
                : jobVacancies.map((vacancy, index) => (
                    <div className="card-vacancy" key={index}>
                      <div className="content">
                        <span>
                          {vacancy.is_remote ? 'Remoto' : vacancy.location}
                        </span>
                        <div className="vacancy fontsize28">{vacancy.name}</div>
                        <div className="skills">
                          {vacancy.skills.map((skill, i) => (
                            <span key={i}>{skill.name}</span>
                          ))}
                        </div>
                      </div>
                      <div className="icon">
                        <NavLink
                          to={{
                            pathname: `/careers/vacancy/${vacancy.id}`,
                          }}
                        >
                          <KeyboardArrowRight />
                        </NavLink>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>

      <SiteMap />
    </Layout>
  );
};

Careers.propTypes = {
  jobVacancies: PropTypes.array,
};

const mapStateToProps = state => ({
  jobVacancies: state.careers.data,
});

export default connect(mapStateToProps)(Careers);
