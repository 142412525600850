import React from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

import { LOCALES } from './locales';
import messages from './messages';

const I18nProvider = ({ children, locale }) => (
  <IntlProvider
    locale={locale}
    textComponent={React.Fragment}
    messages={messages[locale]}
  >
    {children}
  </IntlProvider>
);

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
};

I18nProvider.defaultProps = {
  locale: LOCALES.PORTUGUESE,
};

export default I18nProvider;
