import React from 'react';
import { useIntl } from 'react-intl';

import ScrollAnimation from 'react-animate-on-scroll';

// components
import { Layout, ReadyToTalk, SiteMap } from '../../components';

// others

import { pageView } from '../../utils/Mautic.util';

import ServiceSection from './ServiceSection';
import TestmonySection from './TestmonySection';

const Services = () => {
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    pageView();
  }, []);

  return (
    <Layout className="services" showFooter showHeader testID="Services">
      <ServiceSection />
      <TestmonySection />
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk
          type="default"
          getInTouch={formatMessage({ id: 'readtyToTalkTitle' })}
        />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

export default Services;
