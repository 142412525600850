import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Container = ({ children, scrollable, testID }) => {
  return (
    <div
      className={cls('container', { scrollable })}
      data-testid={`${testID}_Container`}
    >
      {children}
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  scrollable: PropTypes.bool,
  testID: PropTypes.string,
};

Container.defaultProps = {
  testID: 'Container',
};

export default Container;
