import { LOCALES } from '../locales';

const PtBr = {
  [LOCALES.PORTUGUESE]: {
    hello: 'Olá, {name}!',
    homeTitle: 'soluções customizadas',
    homeTitle2: 'para problemas reais',
    homeSubTitle: 'Nosso propósito é construir soluções tecnológicas ',
    homeSubTitle2:
      'sob medida para projetos de transformação digital, com foco ',
    homeSubTitle3: 'na experiência do usuário.',
    letsTalk: 'Vamos conversar',
    seeMoreButton: 'ver mais',
    sendFormButton: 'ENVIAR',
    homeClientsTitle: 'CLIENTES',
    homeClientsText: 'Temos orgulho em co-criar soluções ',
    homeClientsText2: 'significativas',
    homeCaseTitle: 'cases',
    homeCaseSubTitle: 'Conheça nossos',
    seeAllCases: 'Todos nossos cases',
    seeAllArticles: 'todos nossos artigos',
    consultingTitle: 'Consultoria',
    consultingSubTitle: 'Inovação corporativa e transformação digital',
    designTitle: 'design UX/UI',
    designSubTitle: 'Tornar a jornada do usuário funcional e amigável ',
    developmentTitle: 'desenvolvimento',
    serviceTitle: 'Outsourcing',
    serviceSubTitle: 'IT outsourcing | Team-as-a-service',
    developmentSubTitle: 'Softwares e aplicativos mobile e web',
    homeCeoBio1:
      'Engenheiro de Software, pos graduado em gestão de projetos ágeis ',
    homeCeoBio2: 'com ampla experiência na área de tecnologia. ',
    homeCeoBio3:
      'Fez parte da Datasul, TOTVS, e foi co-founder das startups Pipefy e minestore.',
    homeCooBio1: 'Economista, executivo empreendedor com anos de ',
    homeCooBio2: 'atuação como sócio, diretor e conselheiro de empresas e ',
    homeCooBio3: 'startups nos segmentos de saúde, varejo e tecnologia.',
    readtyToTalkTitle: 'entre em contato',
    readtyToTalkContent: 'vamos criar algo incrível juntos!',
    newsLetterSubtitle: 'Inscreva-se em nossa',
    newsLetterTitle: 'Newsletter',
    newsLetterContent:
      'e receba informações sobre transformação digital e novidades da bycoders',
    newsLetterTerms1:
      'Ao inserir seu endereço de e-mail, você concorda com nossos',
    newsLetterTerms2: 'Termos de Uso',
    newsLetterTerms3: ' e ',
    newsLetterTerms4: 'Política de Privacidade',
    newsLetterTerms5: ' e concorda em receber e-mails da bycoders.',
    siteMapCol1Title: 'website',
    siteMapCol1Content1: 'Serviços',
    siteMapCol1Content2: 'Cases',
    siteMapCol1Content3: 'Sobre nós',
    siteMapCol1Content4: 'Vamos conversar',
    siteMapCol1Content5: 'Carreiras',
    siteMapCol1Content6: 'Artigos',
    siteMapCol2Title: 'Escritórios remotos',
    siteMapCol2Content1: 'Brasil',
    siteMapCol2Content2: 'Portugal',
    siteMapCol3Title: 'CONDIÇÕES LEGAIS E PRIVACIDADE',
    siteMapCol3Content1: 'Legal',
    siteMapCol3Content2: 'Política de privacidade',
    menuHeader1: 'Serviços',
    menuHeader2: 'Cases',
    menuHeader3: 'bycoders_',
    menuHeader4: 'Sobre nós',
    menuHeader5: 'Carreiras',
    menuHeader6: 'Artigos',
    menuHeader7: 'Contato',
    hoverReadMore: 'leia mais',
    readMore: 'Saiba mais',
    ourServicesTitle: 'nossos',
    ourServicesTitle2: 'SERVIÇOS',
    ServiceDataTitle: 'Dados',
    ServiceDataText:
      'Oferecemos serviços de consultoria e implementação para garantir uma transformação digital rápida e segura. Otimizamos a interpretação de dados com visualizações em tempo real. Criamos dashboards intuitivos que facilitam a compreensão e a análise de grandes volumes de dados, proporcionando uma tomada de decisão informada.',
    ServiceSoftwareDevelopmentTitle: 'Desenvolvimento de software',
    ServiceSoftwareDevelopmentText:
      'Criamos soluções de software sob medida que se alinham perfeitamente com os fluxos de trabalho e necessidades específicas de sua empresa. Nosso serviço abrange todo o ciclo de vida do desenvolvimento, desde a definição de requisitos, arquitetura de software, codificação, testes, até a manutenção e suporte pós-implantação.',
    ServiceTeamAsAServiceTitle: 'Team as a service',
    ServiceTeamAsAServiceText:
      'Team as a Service é um modelo de negócio que permite às empresas contratar equipes completas de desenvolvedores, designers e outros especialistas conforme a necessidade. Isso facilita a escalabilidade dos projetos e a adaptação rápida às mudanças de demanda, sem os custos fixos associados à contratação permanente de funcionários​.',
    ServiceDigitalTransformationTitle: 'Transformação digital',
    ServiceDigitalTransformationText:
      'Apoiamos os nossos clientes  na transformação digital ao integrar tecnologias em todas as áreas da empresa, automatizando processos, utilizando dados estrategicamente, promovendo inovação contínua e melhorando a experiência do cliente. Oferecemos suporte no mapeamento de necessidades, planejamento de projetos e execução de estratégias, garantindo uma transição eficaz e alinhada com os objetivos empresariais.',
    ServiceSustentationAndSuportTitle: 'Sustentação e Suporte',
    ServiceSustentationAndSuportText:
      'Oferecemos monitoramento contínuo, suporte técnico dedicado, integrações, atualizações regulares de software e respostas rápidas a incidentes (correção de bugs, identificação de incompatibilidades) , assegurando que seus sistemas operem de forma ininterrupta e com desempenho máximo.',
    ServiceDesignUIUXTitle: 'Design UI/UX',
    ServiceDesignUIUXText:
      'Nosso desenvolvimento de software inclui um forte foco no design e na experiência do usuário (UX/UI). Criamos interfaces intuitivas e agradáveis, garantindo que o software seja fácil de usar e ofereça uma experiência excelente aos seus usuários.O processo envolve pesquisa com usuários, prototipagem, testes de usabilidade e design visual para garantir que os produtos atendam às necessidades e expectativas dos usuários finais​.',
    weAreSpecialists: 'somos especialistas',
    weAreSpecialists2: 'em acelerar negócios e alcançar resultados',
    testmonials: 'DEPOIMENTOS',
    testmonials2: 'dos nossos clientes',
    servicePageSubtitle: 'desenvolvimento de soluções tecnológicas sob medida',
    servicePageTitle:
      'Somos especialistas em acelerar negócios e alcançar resultados',
    servicePageContent:
      'Os serviços de consultoria, design e fábrica de software são desenvolvidos com base na  inteligência estratégica, focada na transformação digital e experiência do usuário, para a co-criação de soluções sob medida para cada cliente.',
    servicePageConsulting1:
      'Na era da transformação digital, não basta criar softwares. Entendemos que a verdadeira mudança começa ao mergulhar nos desafios específicos de cada negócio. Por isso, oferecemos consultoria especializada para guiar sua empresa na jornada rumo ao futuro, utilizando a tecnologia como catalisadora.',
    servicePageConsulting2:
      'Nossa consultoria, respaldada por pesquisa e experimentação, proporcionam soluções inovadoras que capacitam o seu negócio através da tecnologia.',
    servicePageDesign:
      'Cada projeto é singular e para assegurar a usabilidade das nossas aplicações, contamos com uma equipe de especialistas em design de produtos digitais. Baseamos todas as decisões na jornada do usuário, transcendendo a mera estética. Desenvolvemos um framework que utiliza métodos e ferramentas para mapear desafios, diagnosticar problemas e propor soluções. Isso evita surpresas durante o processo, assegurando a qualidade da entrega final.',
    servicePageDevelopment:
      'Como fábrica de software, somos especialistas em desenvolvimento de softwares e aplicativos sob medida, utilizando diversas tecnologias como: Ruby on Rails, React, React Native, Node js, Python e .NET CORE, entre outras.',
    servicePageService:
      'Montamos equipes excepcionais, sob medida para os projetos dos clientes. Cada profissional alocado recebe suporte constante de nosso time especializado em Pessoas e Cultura (soft skills), Tech Leads e o próprio CTO da bycoders_. Além disso, nosso CTO interage pessoalmente com os gestores dos projetos dos clientes pelo menos uma vez por mês, proporcionando uma conexão única e potencializando os resultados.',
    casesPageTitle: 'cases de clientes',
    casesPageSubtitle:
      'Conheça alguns de nossos clientes e saiba como nós contribuímos com estas empresas!',
    aboutPageSubtitle: 'Conheça mais sobre',
    aboutPageTitle: 'A bycoders_',
    aboutPageSocialMedia: 'Sigam-nos nas redes sociais',
    aboutPageContent1:
      'Somos uma software house, com alma de startup, que tem em seu DNA soluções tecnológicas sob medida com foco na experiência do usuário, garantindo que estes aconteçam no timing adequado.',
    aboutPageContent2:
      'Entendemos a tecnologia como meio para alcançar objetivos, nunca o fim, por isso nosso processo de transformação digital alia ferramentas, métodos e pessoas, componentes indispensáveis para obtenção de resultados de alto desempenho.',
    aboutPagePurposeTitle: 'Propósito',
    aboutPagePurposeContent:
      'Construir soluções tecnológicas sob medida para projetos de transformação digital com foco na experiência do usuário. ',
    aboutPageVisionTitle: 'Visão',
    aboutPageVisionContent:
      'Ser reconhecidos internacionalmente como uma Software Houses Brasileira referência em transformação digital, até 2026.',
    meetFounder: 'Conheça os fundadores',
    careerPageSubtitle: 'VENHA SER coder_!',
    careerPageTitle: 'CARREIRAS',
    careerPageOurTeamTitle: 'Nosso time',
    careerPageOurTeam1:
      'Somos especialistas em tecnologia e apaixonados pelo que fazemos. Inovar é uma constante para nós. Um time ágil e multidisciplinar composto por engenheiros de softwares,  consultores, desenvolvedores web e mobile, web designers experts em User Experience e User Interface.',
    careerPageOurTeam2:
      'Como  presente de 1 ano da nossa fundação, recebemos o selo Great Place to Work® com  nota 98, o que atesta a nossa preocupação em cuidar dos nossos coders_, maneira que nos referimos às pessoas que trabalham conosco.',
    careerPageOurTeam3:
      'Acolhimento, resultado e aprendizado são nossos valores!',
    careerPageWorkAnywhereTitle: 'Trabalhe de qualquer lugar',
    careerPageWorkAnywhere1:
      'Entendemos que a oportunidade de seleção de talentos não tem fronteiras e por isso, desde sua fundação a bycorders_ é 100% remota.',
    careerPageWorkAnywhere2:
      'A metodologia de seleção, contratação e processos de trabalho são todos formatados para gestão à distância, mas de forma que todos se sintam próximos, mesmo morando em diferentes cidades ou países.',
    careerPageWorkAnywhere3:
      'Temos valores fortes, gostamos de desafios e acreditamos que o nosso negócio é feito por pessoas (Coders) para pessoas (Clientes). Por isso, gostamos de quem gosta de aprender sempre, curte compartilhar e sabe se autogerenciar.',
    careerPageWorkAnywhere4:
      'Se você se enxergou fazendo parte disso tudo, Welcome aboard!',
    careerPagePosition: 'Oportunidades',
    articlesPageTitle: 'artigos',
    articlesPageSubtitle:
      'Conteúdo relevante para construir um mundo de negócios melhor.',
    pageContactTitle1: 'Vamos conversar',
    pageContactSubtitle: 'Pronto para',
    pageContactTitle2: 'INOVAR CONOSCO?',
    pageContactPhone1: 'Você pode nos contatar por:',
    pageContactPhone2: 'telefone ou WhatsApp ',
    pageContactPhone3: 'contato@bycoders.com.br',
    pageContactFormTitleInfo: 'Você pode nos contatar por:',
    pageContactFormTitle:
      'Ou, preencha suas informações abaixo e entraremos em contato.',
    pageContactFormSubtitle:
      'Respondemos em até 24 horas úteis após o envio da mensagem.',
    pageContactFormQt1Title: 'Como podemos te ajudar?',
    pageContactFormQt1Option1: 'Dados',
    pageContactFormQt1Option2: 'Desenvolvimento de Software',
    pageContactFormQt1Option3: 'Team as a service',
    pageContactFormQt1Option4: 'Transformação digital',
    pageContactFormQt1Option5: 'Sustentação e Suporte',
    pageContactFormQt1Option6: 'Design UX/UI',
    pageContactFormQt1Option7: 'Outros',
    pageContactFormQtName: 'Qual o seu nome?',
    pageContactFormQtCompany: 'Qual é o nome da sua empresa / projeto?',
    pageContactFormQtEmail: 'Qual o seu endereço de e-mail?',
    pageContactFormQtNumber:
      'Você gostaria de deixar um número de telefone para contato?',
    pageContactFormQtContactTitle: 'Como você prefere o contato?',
    pageContactFormQtContactOp1: 'WhatsApp',
    pageContactFormQtContactOp2: 'Ligação',
    pageContactFormQtContactOp3: 'E-mail',
    pageContactFormQtBudgetTitle: 'Qual é o seu orçamento?',
    pageContactFormQtBudgetOp1: 'Menor que  $25.000,00',
    pageContactFormQtBudgetOp2: '$25,000.00 - $50,000,00',
    pageContactFormQtBudgetOp3: '$50,000.00 - $100,000.00',
    pageContactFormQtBudgetOp4: '$100.000,00 ou mais',
    pageCaseCarouselTitle: 'cases',
    pageCaseCarouselSubtitle: 'outros cases',
    pageArticleCarouselTitle: 'artigos',
    pageArticleCarouselSubtitle: 'outros artigos',
    pageArticleTitle: 'artigo',
    pageCaseTitle: 'case',
    pageCaseButtonBack: 'todos os cases',
    pageArticleButtonBack: 'todos os artigos',

    pagePrivacyPoliceTitle: 'Política de privacidade do site bycoders_',
    pagePrivacyPoliceContent1: 'No site da',
    pagePrivacyPoliceContent2: 'bycoders_',
    pagePrivacyPoliceContent3: ', acessível em',
    pagePrivacyPoliceContent24: 'https://www.bycoders.co',
    pagePrivacyPoliceContent25:
      'uma de nossas principais prioridades é a privacidade de nossos visitantes. Este documento de Política de Privacidade contém tipos de informações que são coletadas e registradas pelo site da bycoders_ e como as usamos.',
    pagePrivacyPoliceContent4:
      'Se você tiver perguntas adicionais ou precisar de mais informações sobre nossa Política de Privacidade, não hesite em nos contatar.',
    pagePrivacyPoliceSubtitle1: 'O site do Log Files',
    pagePrivacyPoliceContent5:
      'A bycoders_ segue um procedimento padrão de uso de arquivos de log. Esses arquivos registram os visitantes quando eles visitam sites. Todas as empresas de hospedagem fazem isso e fazem parte das análises dos serviços de hospedagem. As informações coletadas por arquivos de log incluem endereços de protocolo de Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), carimbo de data e hora, páginas de referência / saída e, possivelmente, o número de cliques. Eles não estão vinculados a nenhuma informação de identificação pessoal. O objetivo das informações é analisar tendências, administrar o site, rastrear o movimento dos usuários no site e coletar informações demográficas. Nossa Política de Privacidade foi criada com a ajuda do Gerador de Política de Privacidade.',
    pagePrivacyPoliceSubtitle2: 'Cookies e Web Beacons',
    pagePrivacyPoliceContent6: 'Como qualquer outro site, o da',
    pagePrivacyPoliceContent7:
      'usa ’cookies’. Esses cookies são usados ​​para armazenar informações, incluindo as preferências dos visitantes e as páginas do site que o visitante acessou ou visitou. As informações são usadas para otimizar a experiência dos usuários, personalizando o conteúdo da nossa página da web com base no tipo de navegador dos visitantes e / ou outras informações.',
    pagePrivacyPoliceContent8:
      'Para obter mais informações gerais sobre cookies, leia o artigo "O que são cookies" no site Cookie Consent.',
    pagePrivacyPoliceSubtitle3: 'Políticas de privacidade',
    pagePrivacyPoliceContent9:
      'Você pode consultar esta lista para encontrar a Política de Privacidade de cada um dos parceiros de publicidade do',
    pagePrivacyPoliceContent10: 'Website.',
    pagePrivacyPoliceContent11:
      'Servidores de anúncios de terceiros ou redes de anúncios usam tecnologias como cookies, JavaScript ou Web Beacons que são usados ​​em seus respectivos anúncios e links que aparecem no site da',
    pagePrivacyPoliceContent12:
      'que são enviados diretamente para o navegador dos usuários. Eles recebem automaticamente o seu endereço IP quando isso ocorre. Essas tecnologias são usadas para medir a eficácia de suas campanhas publicitárias e / ou para personalizar o conteúdo publicitário que você vê nos sites que visita.',
    pagePrivacyPoliceContent13: 'Observe que o Website da',
    pagePrivacyPoliceContent14:
      'não tem acesso ou controle sobre esses cookies que são usados ​​por anunciantes terceiros.',
    pagePrivacyPoliceSubtitle4: 'Políticas de privacidade de terceiros ',
    pagePrivacyPoliceContent15:
      'A Política de privacidade do site da bycoders_ não se aplica a outros anunciantes ou sites. Assim, aconselhamos você a consultar as respectivas Políticas de Privacidade desses servidores de anúncios de terceiros para obter informações mais detalhadas. Pode incluir suas práticas e instruções sobre como cancelar certas opções.',
    pagePrivacyPoliceContent16:
      'Você pode optar por desabilitar os cookies por meio das opções individuais do seu navegador. Para saber informações mais detalhadas sobre o gerenciamento de cookies com navegadores da web específicos, elas podem ser encontradas nos respectivos sites dos navegadores. O que são cookies?',
    pagePrivacyPoliceSubtitle5: 'Informações para crianças',
    pagePrivacyPoliceContent17:
      'Outra parte de nossa prioridade é adicionar proteção para as crianças durante o uso da Internet. Incentivamos os pais e responsáveis ​​a observar, participar e / ou monitorar e orientar suas atividades online.',
    pagePrivacyPoliceContent26: 'O site da',
    pagePrivacyPoliceContent18:
      'não coleta intencionalmente nenhuma informação de identificação pessoal de crianças menores de 13 anos. Se você acha que seu filho forneceu esse tipo de informação em nosso site, recomendamos que você entre em contato conosco imediatamente e faremos o possível para prontamente remover essas informações de nossos registros.',
    pagePrivacyPoliceSubtitle6: 'Política de Privacidade Online Apenas',
    pagePrivacyPoliceContent19:
      'Esta Política de Privacidade se aplica apenas às nossas atividades online e é válida para os visitantes do nosso site no que diz respeito às informações que eles compartilharam e / ou coletaram no site da',
    pagePrivacyPoliceContent20:
      'Esta política não se aplica a nenhuma informação coletada offline ou por meio de outros canais que não este site.',
    pagePrivacyPoliceSubtitle7: 'Consentimento',
    pagePrivacyPoliceContent21:
      'Ao utilizar nosso site, você concorda com nossa Política de Privacidade e concorda com seus Termos e Condições.',
    pagePrivacyPoliceContent22:
      'Dúvidas para esclarecimentos, entre em contato com nosso responsável:',
    pagePrivacyPoliceContent23: 'Vanildo Prates - contato@bycoders.com.br',
    subscribeButton: 'inscreva-se',
    messageSawAllCases: 'Você viu todos os cases!',
    messageSawAllArticles: 'Você viu todos os artigos!',
    msgNotFound: 'Ops! Desculpe, não conseguimos encontrar a página.',
    buttonNotFound: 'ir para home',
    newsletterRequiredField: 'Campo obrigatório',
    newsletterValidField: 'Digite um email válido.',
    newsletterSuccess: 'E-mail registrado. Agradecemos sua inscrição!',
    contactConfirmationTitle: 'Agradecemos o seu contato!',
    contactConfirmationSubtitle:
      'Nós te retornaremos nas próximas 24h, considerando dias úteis.',
    contactConfirmationParagraph:
      'Enquanto isso, que tal ler um de nossos artigos?',
    careerPageTitleOurOpportunities: 'Nossas oportunidades',
    careerPageFilterCountry: 'País',
    careerPageFilterPlace: 'Local',
    careerPageFilterTypeContract: 'Tipo de contrato',
    careerPageFilterArea: 'Área',
    careerPageFilterEnterVancancy: 'Digite sua vaga',
    careerPageApplyVancancy: 'Candidate-se',
  },
};

export default PtBr;
