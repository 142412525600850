import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import ActiveContent from './ActiveContent';
import PropTypes from 'prop-types';

const ServiceCardMobile = ({
  id,
  title,
  text,
  isDefaultActive,
  actionLink,
  img,
  clientimg,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(isDefaultActive);
  const serviceRef = useRef();
  const imgRef = useRef();

  const handleHover = () => {
    serviceRef.current.addEventListener('mouseenter', () => {
      setIsHovered(true);

      serviceRef.current.classList.add('service-card-container-active');

      imgRef.current && imgRef.current.classList.add('service-card-img-active');
    });

    serviceRef.current.addEventListener('mouseleave', () => {
      setIsHovered(false);

      serviceRef.current.classList.remove('service-card-container-active');

      imgRef.current &&
        imgRef.current.classList.remove('service-card-img-active');
    });
  };

  const toggleCard = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    handleHover();
  }, []);

  useEffect(() => {
    if (isActive) {
      serviceRef.current.classList.add('active');
    } else {
      serviceRef.current.classList.remove('active');
    }
  }, [isActive]);

  return (
    <div id={id} ref={serviceRef} className="service-card-mobile-container">
      <Header
        title={title}
        isHovered={isHovered}
        isActive={isActive}
        toggleCard={toggleCard}
      />
      {!isActive &&
        React.cloneElement(img, {
          ref: imgRef,
          className: 'service-card-mobile-img',
        })}
      {isActive && (
        <ActiveContent
          text={text}
          actionLink={actionLink}
          clientimg={clientimg}
        />
      )}
    </div>
  );
};

ServiceCardMobile.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.node,
  isDefaultActive: PropTypes.bool,
  actionLink: PropTypes.string,
  clientimg: PropTypes.node,
};

export default ServiceCardMobile;
