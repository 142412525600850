import { combineReducers } from '@reduxjs/toolkit';

import { articlesReducer } from './articles';
import { careersReducer } from './careers';
import { casesReducer } from './cases';
import { clientsReducer } from './clients';

const rootReducer = combineReducers({
  articles: articlesReducer,
  careers: careersReducer,
  cases: casesReducer,
  clients: clientsReducer,
});

export default rootReducer;
