import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

// components
import LanguageDropdown from '../LanguageDropdown';

// others
import close from '../../assets/images/close.svg';
import Logo from '../../assets/images/bycoders_.svg';

const Sidebar = ({ toggleSidebar, testID }) => {
  const { formatMessage } = useIntl();
  const navItems = [
    { name: formatMessage({ id: 'siteMapCol1Content1' }), path: '/services' },
    { name: formatMessage({ id: 'siteMapCol1Content2' }), path: '/cases' },
    { name: formatMessage({ id: 'siteMapCol1Content5' }), path: '/careers' },
    { name: formatMessage({ id: 'siteMapCol1Content3' }), path: '/about' },
    { name: formatMessage({ id: 'siteMapCol1Content4' }), path: '/contact' },
  ];

  return (
    <div data-testid={`${testID}_Sidebar`} className="header-mobile">
      <div
        data-testid={`${testID}_Sidebar_Content`}
        className="header-mobile-content"
      >
        <div data-testid={`${testID}_Sidebar_Nav`} className="nav">
          <div>
            <NavLink to="/" onClick={toggleSidebar}>
              <img
                data-testid={`${testID}_Sidebar_Image`}
                className="logo"
                src={Logo}
                alt="Logo"
              />
            </NavLink>
          </div>
          <div className="content">
            <div className="toggle">
              <LanguageDropdown type="secondary" />
            </div>
            <div
              data-testid={`${testID}_Sidebar_Closed`}
              className="closed"
              onClick={toggleSidebar}
            >
              <img
                data-testid={`${testID}_Sidebar_Closed_Image`}
                src={close}
                alt="close"
              />
            </div>
          </div>
        </div>
        <div className="header-list">
          {navItems.map((item, i) => (
            <a key={`item_${i}`} href={item.path} target="">
              {item.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  testID: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};
Sidebar.defaultProps = {
  testID: 'Sidebar',
};
export default Sidebar;
