import React from 'react';
import PropTypes from 'prop-types';

// others
import { ApiService } from '../../services';
import { saveToStorage } from '../../utils/localStorage';

// local
import { appReducer, appInitialState } from './App.provider.util';

const api = new ApiService();

const AppContext = React.createContext({
  api: api,
  siteLang: undefined,
  themeName: undefined,
  setSiteLang: undefined,
  setTheme: undefined,
});

export const useApp = () => React.useContext(AppContext);

const AppProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(appReducer, appInitialState);

  const setTheme = themeName => {
    dispatch({ type: 'setTheme', themeName });
    saveToStorage('themeName', themeName);
  };

  const setSiteLang = siteLang => {
    dispatch({ type: 'setLang', siteLang });
    saveToStorage('siteLang', siteLang);
    setRootLang(siteLang?.split('-')[0]);
  };

  const setRootLang = lang => {
    const root = document.getElementsByTagName('html')[0];
    root.setAttribute('lang', lang);
  };

  return (
    <AppContext.Provider
      value={{
        api,
        siteLang: state?.siteLang,
        themeName: state?.themeName,
        setSiteLang,
        setTheme,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProvider;
