import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import cls from 'classnames';
import { Link } from 'react-router-dom';
import { pageView } from '../../utils/Mautic.util';

import Button from '../Button';
import Loader from '../Loader';

import { useApp } from '../../providers/App';

const NewsLetter = ({ testID }) => {
  const { formatMessage } = useIntl();
  const { api } = useApp();
  const [success, setSuccess] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState({
    email: '',
  });
  const [data, setData] = React.useState({
    email: [],
    loading: false,
    error: null,
  });

  const handleOnChangeEmail = e => {
    setEmail(e.target.value);
    setError('');
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      if (!validateEmail(email)) return;

      setData(prev => ({
        ...prev,
        loading: true,
        error: null,
        email: [],
      }));

      const { data: _data, status } = await api.subscribe(email);
      if (status !== 200) throw new Error('Falha ao cadastrar e-mail.');
      pageView({ contact_email: email });
      setData(prev => ({
        ...prev,
        email: _data,
        loading: false,
      }));
      setEmail('');
      setSuccess(formatMessage({ id: 'newsletterSuccess' }));
      clearSuccessMessage();
    } catch (error) {
      const _error =
        typeof error === 'string'
          ? error
          : 'Ocorreu um erro ao tentar cadastrar e-mail.';
      setData(prev => ({
        ...prev,
        loading: false,
        error: _error,
      }));
    }
  };

  const validateEmail = () => {
    const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    if (!email) {
      setError(prev => ({
        ...prev,
        email: formatMessage({ id: 'newsletterRequiredField' }),
      }));
      return false;
    }

    if (!regex.test(email)) {
      setError(true);
      setError(prev => ({
        ...prev,
        email: formatMessage({ id: 'newsletterValidField' }),
      }));
      return false;
    }
    return true;
  };

  const clearSuccessMessage = () => {
    const clear = setTimeout(() => {
      setSuccess('');
    }, 3000);

    return () => clearTimeout(clear);
  };

  return (
    <div
      data-testid={`${testID}_NewsLetter`}
      className={cls('newsLetter-content')}
    >
      {false && (
        <div className="newsletter-container">
          <div
            data-testid={`${testID}_NewsLetter_Content`}
            className={cls('newsLetter')}
          >
            <span className={cls('subtitle')}>
              {formatMessage({ id: 'newsLetterSubtitle' })}
            </span>
            <br />
            <br />
            <span className={cls('title')}>
              {formatMessage({ id: 'newsLetterTitle' })}
            </span>
            <p className={cls('text')}>
              {formatMessage({ id: 'newsLetterContent' })}
            </p>
          </div>
          <div
            data-testid={`${testID}_NewsLetter_Form`}
            className={cls('form')}
          >
            <input
              type="email"
              placeholder="E-mail"
              name="email"
              value={email}
              onChange={handleOnChangeEmail}
            />
            {!!success && (
              <p className={cls('success')}>
                {formatMessage({ id: 'newsletterSuccess' })}
              </p>
            )}
            {error.email && <p className={cls('error')}>{error.email}</p>}
            <div
              data-testid={`${testID}_NewsLetter_Terms`}
              className={cls('terms-submmit')}
            >
              <p className={cls('terms')}>
                {formatMessage({ id: 'newsLetterTerms1' })}{' '}
                <Link to="/privacy-police">
                  <span>{formatMessage({ id: 'newsLetterTerms2' })}</span>
                </Link>
                &nbsp;
                {formatMessage({ id: 'newsLetterTerms3' })}
                <Link to="/privacy-police">
                  <span> {formatMessage({ id: 'newsLetterTerms4' })} </span>
                </Link>
                &nbsp;
                {formatMessage({ id: 'newsLetterTerms5' })}
              </p>
              <div className="terms-submmit-subscribe">
                <Button
                  data-testid={`${testID}_NewsLetter_Btn`}
                  onClick={handleSubmit}
                  type="headerButton"
                  loading={data.loading}
                >
                  {formatMessage({ id: 'subscribeButton' })}
                </Button>
                {data.loading && <Loader size="small" />}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

NewsLetter.propTypes = {
  testID: PropTypes.string.isRequired,
};

NewsLetter.defaultProps = {
  testID: 'NewsLetter',
};

export default NewsLetter;
