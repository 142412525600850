import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '../Button';

// providers
import { useApp } from '../../providers/App';

const ReadyToTalk = ({ getInTouch, testID, type }) => {
  const { formatMessage } = useIntl();
  const { siteLang } = useApp();

  return (
    <div
      className="content-ready-to-talk"
      data-testid={`${testID}_ReadyToTalk_Content`}
    >
      <div className="readyToTalk">
        <div
          className={cls('container', type)}
          data-testid={`${testID}_ReadyToTalk_Wrapper`}
        >
          <div className={cls('infos', type)}>
            {getInTouch && <span className="get-in-touch">{getInTouch}</span>}
            <span className={cls('infos-title', type)}>
              {formatMessage({ id: 'readtyToTalkContent' })}
            </span>
          </div>
          <div className={cls('ready-button', type)}>
            <Link to="/contact">
              <Button
                type="solidLarge"
                fullWidth
                size={siteLang !== 'pt-BR' ? 'medium' : 'large'}
              >
                {formatMessage({ id: 'letsTalk' })}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ReadyToTalk.propTypes = {
  getInTouch: PropTypes.string,
  testID: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.oneOf(['default', 'small']),
};

ReadyToTalk.defaultProps = {
  testID: 'ReadyToTalk',
  text: 'READY TO TALK ABOUT YOUR PROJECT?',
};

export default ReadyToTalk;
