import React from 'react';
import { useIntl } from 'react-intl';

const TestmonySection = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="testimony-section-container">
      <div className="testimony-section-header-videos">
        <div className="testimony-section-header">
          <div className="testimony-section-header-title">
            {formatMessage({ id: 'testmonials' })}
          </div>
          <div className="testimony-section-header-subtitle-wrapper">
            <div className="testimony-section-header-subtitle">
              {formatMessage({ id: 'testmonials2' })}
            </div>
            <div className="testimony-section-header-underscore" />
          </div>
        </div>
        <div className="testimony-section-videos-wrapper">
          <iframe
            src="https://www.youtube.com/embed/qKchD3k60j0?si=8HlSDJeCRiMW_2H7"
            title="bycoders_"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="testimony-section-video"
          ></iframe>
          <iframe
            src="https://www.youtube.com/embed/JNvxtkqa9DY?si=4hYB64kDNEzH4mVG"
            title="bycoders_"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="testimony-section-video"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default TestmonySection;
