import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import BrazilFlag from '../../../assets/images/icons/brazil-svgrepo-com.svg';
import EnglishFlag from '../../../assets/images/icons/united-states-svgrepo-com.svg';

import { useApp } from '../../../providers/App';

const LanguageSelected = ({ id, isOpen, handleDropdown, type }) => {
  const { siteLang } = useApp();
  const [flag, setFlag] = useState();
  const [label, setLabel] = useState();
  const labelRef = useRef(null);

  const handleClick = () => {
    handleDropdown(!isOpen);
  };

  const clearTypeStyle = () => {
    labelRef.current.classList.remove('primary');
    labelRef.current.classList.remove('secondary');
  };

  useEffect(() => {
    clearTypeStyle();
    labelRef.current.classList.add(type);
  }, []);

  useEffect(() => {
    if (siteLang === 'pt-BR') {
      setFlag(BrazilFlag);
      setLabel('PT');
    }

    if (siteLang === 'en-US') {
      setFlag(EnglishFlag);
      setLabel('EN');
    }
  }, [siteLang]);

  return (
    <div id={id} className="dropdown-selected" onClick={handleClick}>
      <img
        className="dropdown-selected-flag"
        src={flag}
        width={32}
        height={32}
      />
      <div ref={labelRef} className="dropdown-selected-label">
        {label}
      </div>
    </div>
  );
};

LanguageSelected.propTypes = {
  id: PropTypes.string,
  isOpen: PropTypes.bool,
  handleDropdown: PropTypes.func,
  type: PropTypes.string,
};

export default LanguageSelected;
