import React from 'react';
import cls from 'classnames';
import { useIntl } from 'react-intl';

import {
  Input,
  Button,
  Layout,
  SiteMap,
  ContactConfirmation,
} from '../../components';

import { useApp } from '../../providers/App';
import { pageView } from '../../utils/Mautic.util';

import { useOptions } from './Contact.effects';

import { ReactComponent as Whatsapp } from '../../assets/images/whatsapp.svg';
import { ReactComponent as Email } from '../../assets/images/email.svg';

const Contact = () => {
  const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  const { api } = useApp();
  const options = useOptions();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    helpType: '',
    preferredContact: '',
    budget: '',
  });
  const { formatMessage } = useIntl();

  const [dataError, setErrors] = React.useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
  });

  const [company, setCompany] = React.useState({
    client: [],
    loading: false,
    error: null,
  });

  const borderColor = '#D8D8D8';

  const handleOnChange = field => e => {
    setErrors(prev => ({ ...prev, [field]: undefined }));
    setData(prev => ({ ...prev, [field]: e.target.value }));
  };

  const handleContact = async () => {
    const hiddenForm = document.getElementById('hiddenForm');
    hiddenForm.submit();
    try {
      if (validateFields(data) > 0) return;

      const { data: _data, status } = await api.contact(data);

      if (status !== 200) throw new Error('Falha ao enviar formulário.');
      pageView({ contact_data: data });

      const client = _data.data;
      setCompany(prev => ({
        ...prev,
        client,
        loading: false,
      }));

      clearData();

      setOpen(true);
    } catch (error) {
      const _error =
        typeof error === 'string'
          ? error
          : 'Ocorreu um erro ao tentar enviar formulário.';
      setCompany(prev => ({
        ...prev,
        loading: false,
        error: _error,
      }));
    }
  };

  const validateFields = data => {
    const _errors = {};
    if (!data.name) {
      _errors.name = 'Required Field';
    }

    if (!data.companyName) {
      _errors.companyName = 'Required Field';
    }

    if (!data.email) {
      _errors.email = 'Required Field';
    }

    if (!regex.test(data.email)) {
      _errors.email = 'Invalid Email';
    }

    setErrors(prev => ({
      ...prev,
      ..._errors,
    }));

    const hasError = Object.keys(_errors).length;

    return hasError;
  };

  const onChangeField = (id, field) => () => {
    setData(prev => ({ ...prev, [id]: field }));
  };

  const clearData = () => {
    setData(prev => ({
      ...prev,
      name: '',
      companyName: '',
      email: '',
      phone: '',
      helpType: '',
      preferredContact: '',
      budget: '',
    }));
  };

  React.useEffect(() => {
    if (open) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [open]);

  return (
    <Layout className="contact" showFooter showHeader testID="Contact">
      <div className={cls('contact-header', { open })}>
        <div className="contact-header-top">
          <div className="header-title">
            <span>{formatMessage({ id: 'pageContactSubtitle' })}</span>
            <h2>{formatMessage({ id: 'pageContactTitle2' })}</h2>
          </div>
        </div>
        <div className="contact-content">
          <div className="contact-content-infos info-title">
            <span className="infos-title">
              {formatMessage({ id: 'pageContactFormTitleInfo' })}
            </span>
          </div>
          <div className="contact-content-infos">
            <a className="info-item" href="tel:+5541996855782">
              <Whatsapp />
              <span className="info-label">
                <u>+55 41 9 9685 5782</u>
              </span>
            </a>
            <div className="info-item">
              <Email />
              <span className="info-label">
                {formatMessage({ id: 'pageContactPhone3' })}
              </span>
            </div>
          </div>
          <div className="form">
            <div className="contact-content-infos">
              <span className="infos-title">
                {formatMessage({ id: 'pageContactFormTitle' })}
              </span>
              <span className="infos-subtitle">
                {formatMessage({ id: 'pageContactFormSubtitle' })}
              </span>
            </div>
            <div className="contact-content-infos">
              <span className="infos-title">
                {formatMessage({ id: 'pageContactFormQt1Title' })}
              </span>
              {options.HELP_TYPE.map((option, i) => (
                <label key={`options-${i}`}>
                  <Input
                    type="radio"
                    label={option}
                    onChange={onChangeField('helpType', option)}
                    checked={data.helpType === option}
                  />
                </label>
              ))}
            </div>
            <div className="contact-content-infos">
              <span className="infos-title">
                {formatMessage({ id: 'pageContactFormQtName' })}
              </span>
              <Input
                autoComplete={'name'}
                borderColor={borderColor}
                error={dataError?.name}
                required
                type="text"
                value={data.name}
                onChange={handleOnChange('name')}
              />
            </div>
            <div className="contact-content-infos">
              <span className="infos-title">
                {formatMessage({ id: 'pageContactFormQtCompany' })}
              </span>
              <Input
                autoComplete={'organization'}
                borderColor={borderColor}
                error={dataError?.companyName}
                required
                type="text"
                value={data.companyName}
                onChange={handleOnChange('companyName')}
              />
            </div>
            <div className="contact-content-infos">
              <span className="infos-title">
                {formatMessage({ id: 'pageContactFormQtEmail' })}
              </span>
              <Input
                autoComplete={'email'}
                borderColor={borderColor}
                error={dataError?.email}
                required
                type="email"
                value={data.email}
                onChange={handleOnChange('email')}
              />
            </div>
            <div className="contact-content-infos">
              <span className="infos-title">
                {formatMessage({ id: 'pageContactFormQtNumber' })}
              </span>
              <Input
                autoComplete={'phone'}
                borderColor={borderColor}
                type="text"
                value={data.phone}
                onChange={handleOnChange('phone')}
              />
            </div>
            <div className="send">
              <Button type="solidLarge" onClick={handleContact}>
                {formatMessage({ id: 'sendFormButton' })}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {open && !company.error && <ContactConfirmation />}
      <SiteMap />
    </Layout>
  );
};

export default Contact;
