import React, { useEffect, useState } from 'react';

import ServiceCardHome from '../ServiceCardHome';
import { ReactComponent as ServiceCardLogo1 } from '../../assets/images/service-card-logo-1.svg';
import { ReactComponent as ServiceCardLogo2 } from '../../assets/images/service-card-logo-2.svg';
import { ReactComponent as ServiceCardLogo3 } from '../../assets/images/service-card-logo-3.svg';
import { ReactComponent as ServiceCardLogo4 } from '../../assets/images/service-card-logo-4.svg';
import { ReactComponent as ServiceCardLogo5 } from '../../assets/images/service-card-logo-5.svg';
import { ReactComponent as ServiceCardLogo6 } from '../../assets/images/service-card-logo-6.svg';
import getServiceRoute from '../../utils/getServiceRoute';

import { useIntl } from 'react-intl';

const OurServices = () => {
  const { formatMessage } = useIntl();
  const [serviceRoute, setServiceRoute] = useState(null);

  useEffect(() => {
    setServiceRoute(getServiceRoute());
  }, []);

  return (
    <div className="our-services-container">
      <div className="our-services-header-content-wrapper">
        <div className="our-services-header">
          <div className="our-services-header-title">
            {formatMessage({ id: 'ourServicesTitle' })}
          </div>
          <div className="our-services-header-title2">
            {formatMessage({ id: 'ourServicesTitle2' })}
          </div>
        </div>
        <div className="our-services-content">
          <ServiceCardHome
            title={formatMessage({ id: 'ServiceDataTitle' })}
            actionLink={`${serviceRoute}/?active=1`}
            img={<ServiceCardLogo1 />}
          />
          <ServiceCardHome
            title={formatMessage({ id: 'ServiceSoftwareDevelopmentTitle' })}
            actionLink={`${serviceRoute}/?active=2`}
            img={<ServiceCardLogo2 />}
          />
          <ServiceCardHome
            title={formatMessage({ id: 'ServiceTeamAsAServiceTitle' })}
            actionLink={`${serviceRoute}/?active=3`}
            img={<ServiceCardLogo3 />}
          />
          <ServiceCardHome
            title={formatMessage({ id: 'ServiceDigitalTransformationTitle' })}
            actionLink={`${serviceRoute}/?active=4`}
            img={<ServiceCardLogo4 />}
          />
          <ServiceCardHome
            title={formatMessage({ id: 'ServiceSustentationAndSuportTitle' })}
            actionLink={`${serviceRoute}/?active=5`}
            img={<ServiceCardLogo5 />}
          />
          <ServiceCardHome
            title={formatMessage({ id: 'ServiceDesignUIUXTitle' })}
            actionLink={`${serviceRoute}/?active=6`}
            img={<ServiceCardLogo6 />}
          />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
