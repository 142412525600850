/**
 * Supported locales (defined by Strapi Admin).
 */
const LOCALES = Object.freeze({
  en: 'en',
  'en-US': 'en',
  pt: 'pt-BR',
  'pt-BR': 'pt-BR',
});

/**
 * Parse locale to be consistent with the Strapi Admin.
 *
 * @param {string} locale
 * @returns {string}
 */
export const parseLocale = locale => {
  return LOCALES[locale] || locale;
};

/**
 * Parse params to be consistent with the Strapi Admin.
 *
 * @param {object} params
 * @returns {object}
 */
export const parseQueryParams = ({
  page = 1,
  limit = 10,
  language,
  sort,
  ...params
} = {}) => {
  const _start = page * limit - limit;

  return {
    ...(params || {}),
    ...(sort ? { _sort: sort } : {}),
    ...(language ? { _locale: parseLocale(language) } : {}),
    _start,
    _limit: limit,
  };
};
