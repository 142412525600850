import React from 'react';
import Carousel from 'react-multi-carousel';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { assetUrl } from '../../helpers/Strapi.helper';
import Hover from '../Hover';
import { useIntl } from 'react-intl';
import Button from '../Button';

const CustomDot = ({ onClick, active }) => {
  return (
    <li
      className={cls('react-multi-carousel-custom-dot', {
        active,
      })}
      onClick={() => onClick()}
    />
  );
};

CustomDot.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
};

const CustomLeftArrow = () => {
  return <React.Fragment />;
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={() => onClick()}
      className="custom-arrow2"
    ></button>
  );
};

CustomRightArrow.propTypes = {
  onClick: PropTypes.func,
};

const CarouselSlider = ({ data, type, title, testID, subtitle }) => {
  const { formatMessage } = useIntl();

  const items = React.useMemo(
    () =>
      data.slice(-4).map((item, i) => (
        <div
          key={`carousel-item-${i}`}
          className={cls('slide-container', type)}
        >
          <Link
            to={{
              pathname:
                type === 'casesHome'
                  ? `/case/${item.id}`
                  : `/article/${item.id}`,
            }}
          >
            <div className={cls('images', type)}>
              <img src={assetUrl(item.cover, 'medium')} alt="" />
              <div className="hover-carousel">
                <Hover
                  title={formatMessage({ id: 'hoverReadMore' })}
                  type="carouselHome"
                />
              </div>
            </div>
            <div className="infos">
              <h3 className={cls(type)}>{item.title}</h3>
              <span>{item.subtitle}</span>
              <p className={cls(type)}>read more &gt;</p>
            </div>
          </Link>
        </div>
      )),
    [data, type, formatMessage],
  );

  return (
    <div data-testid={`${testID}_Container`} className={cls('carousel', type)}>
      <div className={cls('carousel-container', type)}>
        <div className="carousel-header">
          <div className="carousel-header-content">
            <h5>{subtitle}</h5>
            <h3 className={cls(type)}>{title}</h3>
          </div>
        </div>
        <div className={cls('carousel-content', type)}>
          <Carousel
            additionalTransfrom={0}
            showDots
            arrows
            renderDotsOutside
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            dotListClass=""
            draggable
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
            customDot={<CustomDot />}
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2560,
                  min: 1024,
                },
                items: 3,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: type === 'articlesHome' ? 100 : 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 2,
                partialVisibilityGutter: 30,
              },
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {items}
          </Carousel>
        </div>
        <div className="content-button">
          <div>
            <div className="button">
              <Link to={type === 'casesHome' ? '/cases' : '/articles'}>
                <Button className={cls(type)} type="outlinedLarge">
                  {type === 'casesHome'
                    ? formatMessage({ id: `${'seeAllCases'}` })
                    : formatMessage({ id: `${'seeAllArticles'}` })}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CarouselSlider.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  testID: PropTypes.string,
  type: PropTypes.oneOf(['casesHome', 'articlesHome', 'articlesHomeLarge']),
};

CarouselSlider.defaultProps = {
  data: [],
  subtitle: 'READ OUR CLIENT',
  testID: 'Carousel',
  title: 'CASES',
};

export default CarouselSlider;
