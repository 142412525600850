import { clientsActions } from './clients.store';

export const getClients = (api, { page, limit } = {}) => {
  return async dispatch => {
    try {
      dispatch(clientsActions.getClients());
      const response = await api.getClients({ page, limit });
      const clients = Array.isArray(response?.data) ? response.data : [];
      dispatch(clientsActions.getClientsSuccess({ clients }));
    } catch (error) {
      dispatch(
        clientsActions.getClientsFail({
          error: error?.message || 'Falha ao consultar clientes',
        }),
      );
    }
  };
};
