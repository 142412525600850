import React from 'react';
import ServiceCardDesktop from './ServiceCardDesktop';
import ServiceCardMobile from './ServiceCardMobile';
import PropTypes from 'prop-types';

const ServiceCard = ({
  id,
  title,
  text,
  isDefaultActive,
  activeByHover,
  actionLink,
  img,
  clientimg,
}) => {
  return (
    <>
      <ServiceCardDesktop
        id={id}
        title={title}
        text={text}
        isDefaultActive={isDefaultActive}
        actionLink={actionLink}
        activeByHover={activeByHover}
        img={img}
        clientimg={clientimg}
      />
      <ServiceCardMobile
        id={id}
        title={title}
        text={text}
        actionLink={actionLink}
        img={img}
        clientimg={clientimg}
      />
    </>
  );
};

ServiceCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  isDefaultActive: PropTypes.bool,
  actionLink: PropTypes.string,
  activeByHover: PropTypes.bool,
  img: PropTypes.node,
  clientimg: PropTypes.node,
};

export default ServiceCard;
