import React from 'react';
import { useIntl } from 'react-intl';

const Footer = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="service-section-footer-container">
      <div className="service-section-footer-title-subtitle-wrapper">
        <div className="service-section-footer-title-wrapper">
          <div className="service-section-footer-sidescore" />
          <div className="service-section-footer-title">
            {formatMessage({ id: 'weAreSpecialists' })}
          </div>
        </div>
        <div className="service-section-footer-subtitle">
          {formatMessage({ id: 'weAreSpecialists2' })}
        </div>
      </div>
      <div
        className="service-section-footer-button"
        onClick={() => {
          location.href = '/contact';
        }}
      >
        {formatMessage({ id: 'letsTalk' })}
      </div>
    </div>
  );
};

export default Footer;
