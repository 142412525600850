import React from 'react';
import { useIntl } from 'react-intl';

import { Layout } from '../../components';
import { pageView } from '../../utils/Mautic.util';

const PrivacyPolice = () => {
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    pageView();
  }, []);

  return (
    <Layout
      className="privacy-policy"
      showFooter
      showHeader
      testID="privacy-police"
    >
      <div className="privacy-policy-container">
        <div className="privacy-policy-container-content">
          <h1>{formatMessage({ id: 'pagePrivacyPoliceTitle' })}</h1>
          <p>
            {formatMessage({ id: 'pagePrivacyPoliceContent1' })}&nbsp;
            <span>{formatMessage({ id: 'pagePrivacyPoliceContent2' })}</span>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent3' })}&nbsp;
            <a href={formatMessage({ id: 'pagePrivacyPoliceContent24' })}>
              {formatMessage({ id: 'pagePrivacyPoliceContent24' })}
            </a>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent25' })}
          </p>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent4' })}</p>
          <h3>{formatMessage({ id: 'pagePrivacyPoliceSubtitle1' })}</h3>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent5' })}</p>
          <h3>{formatMessage({ id: 'pagePrivacyPoliceSubtitle2' })}</h3>
          <p>
            {formatMessage({ id: 'pagePrivacyPoliceContent6' })}
            &nbsp;
            <span>{formatMessage({ id: 'pagePrivacyPoliceContent2' })}</span>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent7' })}
          </p>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent8' })}</p>
          <h3>{formatMessage({ id: 'pagePrivacyPoliceSubtitle3' })}</h3>
          <p>
            {formatMessage({ id: 'pagePrivacyPoliceContent9' })}
            &nbsp;
            <span>{formatMessage({ id: 'pagePrivacyPoliceContent2' })}</span>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent10' })}
          </p>
          <p>
            {formatMessage({ id: 'pagePrivacyPoliceContent11' })}
            &nbsp;
            <span>{formatMessage({ id: 'pagePrivacyPoliceContent2' })}</span>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent12' })}
          </p>
          <p>
            {formatMessage({ id: 'pagePrivacyPoliceContent13' })}
            &nbsp;
            <span>{formatMessage({ id: 'pagePrivacyPoliceContent2' })}</span>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent14' })}
          </p>
          <h3>{formatMessage({ id: 'pagePrivacyPoliceSubtitle4' })}</h3>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent15' })}</p>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent16' })}</p>
          <h3>{formatMessage({ id: 'pagePrivacyPoliceSubtitle5' })}</h3>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent17' })}</p>
          <p>
            {formatMessage({ id: 'pagePrivacyPoliceContent26' })}&nbsp;
            <span>{formatMessage({ id: 'pagePrivacyPoliceContent2' })}</span>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent18' })}
          </p>
          <h3>{formatMessage({ id: 'pagePrivacyPoliceSubtitle6' })}</h3>
          <p>
            {formatMessage({ id: 'pagePrivacyPoliceContent19' })}
            &nbsp;
            <span>{formatMessage({ id: 'pagePrivacyPoliceContent2' })}</span>
            &nbsp;
            {formatMessage({ id: 'pagePrivacyPoliceContent20' })}
          </p>
          <h3>{formatMessage({ id: 'pagePrivacyPoliceSubtitle7' })}</h3>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent21' })}</p>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent22' })}</p>
          <p>{formatMessage({ id: 'pagePrivacyPoliceContent23' })}</p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolice;
