import { createSlice } from '@reduxjs/toolkit';

const clientsStore = createSlice({
  name: 'clientsStore',
  initialState: {
    data: [],
    error: '',
    loading: true,
  },
  reducers: {
    getClients: state => ({
      ...state,
      loading: true,
    }),
    getClientsSuccess: (state, { payload }) => ({
      ...state,
      data: payload.clients,
      loading: false,
    }),
    getClientsFail: (state, { payload }) => ({
      ...state,
      error: payload.error,
      loading: false,
    }),
  },
});

export const clientsActions = clientsStore.actions;

export const clientsReducer = clientsStore.reducer;
