import React, { useState } from 'react';
import Menu from '../Menu';
import Sidebar from '../Sidebar';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useAbsolutePosition } from '../Menu/Menu.effects';

const Header = ({ testID }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setOpen(!open);
  };

  const isAbsolute = useAbsolutePosition(location.pathname);
  const isHome = location.pathname === '/';

  let opened = !open ? 'closed' : 'open';

  return (
    <div
      data-testid={`${testID}_Container`}
      className={cls('header', {
        absolute: isAbsolute,
        reverse: !isHome,
      })}
    >
      <div className="header-container">
        <div className={cls(`sidebar ${opened}`)}>
          <Sidebar open={open} toggleSidebar={toggleSidebar} />
        </div>

        <Menu open={open} toggleSidebar={toggleSidebar} />
      </div>
    </div>
  );
};

Header.propTypes = {
  testID: PropTypes.string.isRequired,
};
Header.defaultProps = {
  testID: 'Header',
};

export default Header;
