import axios from 'axios';
import { Service } from 'axios-middleware';

import { getData, getFirst, sanitizeParams } from './Api.interface.util';

class ApiInterface {
  getData = getData;
  getFirst = getFirst;
  sanitizeParams = sanitizeParams;

  axios = axios.create();
  service;

  constructor({ baseURL, headers, responseType } = {}) {
    this.axios = axios.create({
      baseURL,
      responseType,
      headers,
    });
  }

  setAuthorization = authorization => {
    this.axios.defaults.headers.Authorization = authorization;
    return this;
  };

  init = () => {
    this.service = new Service(this.axios);
    this.service.register({
      onRequest(config) {
        return this.onRequest(config);
      },
      onRequestError(error) {
        return this.onRequestError(error);
      },
      onResponse(response) {
        return this.onResponse(response);
      },
      onResponseError(error) {
        return this.onResponseError(error);
      },
    });
  };

  onRequest = config => {
    console.tron.log({
      message: 'API Request',
      config,
    });
    return config;
  };

  onRequestError = error => {
    console.tron.log({
      message: 'API Request Error',
      error,
    });
    return error;
  };

  onResponse = response => {
    console.tron.log({
      message: 'API Response',
      response,
    });
    return response;
  };

  onResponseError = error => {
    console.tron.log({
      message: 'API Response Error',
      error,
    });
    return error;
  };
}

export default ApiInterface;
