import { API_URL } from '../../helpers/Strapi.helper';

import { ApiInterface } from '../interfaces';

import { parseLocale, parseQueryParams } from './Api.service.util';

class Api extends ApiInterface {
  constructor() {
    super({
      baseURL: API_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  #getData = (url, params = {}) =>
    this.axios.get(url, {
      params: parseQueryParams(params),
    });

  /* Articles */

  getArticle = id => this.axios.get(`/articles/${id}`);

  getArticles = ({ page = 1, limit = 10, sort = 'id:desc', language } = {}) =>
    this.#getData('/articles', { page, limit, sort, language });

  getPrioritizedArticles = ({ page = 1, limit = 5, language } = {}) =>
    this.getArticles({ page, limit, sort: 'order:asc', language });

  /* Cases */

  getCase = id => this.axios.get(`/cases/${id}`);

  getCases = ({ page = 1, limit = 10, language } = {}) =>
    this.#getData('/cases', { page, limit, sort: 'order:asc', language });

  /* Clients */

  getClient = id => this.axios.get(`/clients/${id}`);

  getClients = ({ page = 1, limit = 10 } = {}) =>
    this.#getData('/clients', { page, limit });

  /* Job Vacancies */

  getJobVacancy = id => this.axios.get(`/job-vacancies/${id}`);

  getJobVacancies = ({ page = 1, limit = 10, language } = {}) =>
    this.#getData('/job-vacancies', {
      page,
      limit,
      sort: 'name:asc',
      language,
    });

  /* Subscribers */

  subscribe = email => this.axios.post('/subscribers', { email });

  /**
   * Get total count of resource.
   *
   * @param {string} resource
   * @param {{ language: string }} params
   * @returns {Promise<number>}
   */
  count = (resource, { language } = {}) =>
    this.axios.get(`/${resource}/count`, {
      params: {
        _locale: parseLocale(language),
      },
    });

  /* Contact Form */

  contact = data =>
    this.axios.post('/contact-messages', {
      name: data.name,
      company_name: data.companyName,
      email: data.email,
      phone: data.phone,
      help_type: data.helpType,
      prefered_contact: data.preferredContact,
      budget: data.budget,
    });
}

export default Api;
