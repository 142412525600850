import React from 'react';
import { useDispatch } from 'react-redux';

import { useApp } from '../../providers';

import { ArticlesActions } from '../../store';

export const useFetchArticles = (articles, limit = 6) => {
  const dispatch = useDispatch();
  const { api, siteLang: language } = useApp();

  React.useEffect(() => {
    dispatch(ArticlesActions.getArticles(api, { page: 1, limit, language }));
  }, [api, dispatch, limit, language]);

  const getMoreArticles = () => {
    const total = articles?.length || 0;
    dispatch(
      ArticlesActions.getMoreArticles(api, {
        page: Math.floor(total / limit) + 1,
        limit,
        language,
      }),
    );
  };

  return [getMoreArticles];
};
