import React from 'react';
import { useIntl } from 'react-intl';

const Title = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="service-section-title-container">
      <div className="service-section-title">
        {formatMessage({ id: 'menuHeader1' })}
      </div>
      <div className="service-section-title-underscore" />
    </div>
  );
};

export default Title;
