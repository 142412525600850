import mautic from 'mautic-tracking';

let initialize, pageView;

// Report only in production environment
if (window?.location?.hostname?.startsWith('bycoders.com.br')) {
  initialize = () => {
    mautic.initialize('//mautic.bycoders.com.br/mtc.js');
  };
  pageView = mautic.pageView;
} else {
  initialize = () => {};
  pageView = () => {};
}

export { initialize, pageView };
