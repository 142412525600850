import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import PropTypes from 'prop-types';

const ServiceCardHomeDesktop = ({ title, actionLink, img }) => {
  const [isHovered, setIsHovered] = useState(false);
  const serviceRef = useRef();
  const imgRef = useRef();

  const handleHover = () => {
    serviceRef.current.addEventListener('mouseenter', () => {
      setIsHovered(true);

      serviceRef.current.classList.add('service-card-container-active');

      imgRef.current && imgRef.current.classList.add('service-card-img-active');
    });

    serviceRef.current.addEventListener('mouseleave', () => {
      setIsHovered(false);

      serviceRef.current.classList.remove('service-card-container-active');

      imgRef.current &&
        imgRef.current.classList.remove('service-card-img-active');
    });
  };

  useEffect(() => {
    handleHover();
  }, []);

  return (
    <div
      onClick={() => {
        window.open(actionLink, 'blank');
      }}
      ref={serviceRef}
      className="service-card-home-container pointer"
    >
      <Header title={title} isHovered={isHovered} />
      <div className="service-card-home-img-wrapper">
        {React.cloneElement(img, {
          ref: imgRef,
          className: 'service-card-home-img',
        })}
      </div>
    </div>
  );
};

ServiceCardHomeDesktop.propTypes = {
  title: PropTypes.string,
  actionLink: PropTypes.string,
  img: PropTypes.node,
};

export default ServiceCardHomeDesktop;
