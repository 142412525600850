import React from 'react';
import { ThemeProvider } from 'styled-components';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';

// helpers
import {
  HOTJAR_HOTJAR_HJID,
  HOTJAR_HOTJAR_HJSV,
} from './helpers/Hotjar.helper';
import { GOOGLE_ANALYTICS_ID } from './helpers/GoogleAnalytics.helper';

import { I18nProvider, useApp } from './providers';
import { getTheme } from './themes';
import { initialize as initializeMautic } from './utils/Mautic.util';

import Routes from './views/Routes';

initializeMautic();

const App = () => {
  const { siteLang: language, themeName } = useApp();

  React.useEffect(() => {
    hotjar.initialize(HOTJAR_HOTJAR_HJID, HOTJAR_HOTJAR_HJSV);

    /**
     * Initializing GA and Tracking Pageviews:
     */
    ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={getTheme(themeName)}>
      <I18nProvider locale={language}>
        <Routes />
      </I18nProvider>
    </ThemeProvider>
  );
};

export default App;
