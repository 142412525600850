import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';

// components
import { Button, Layout, Loader, ReadyToTalk, SiteMap } from '../../components';

// others
import { assetUrl } from '../../helpers/Strapi.helper';
import { useApp } from '../../providers';
import { pageView } from '../../utils/Mautic.util';

// local
import { useFetchArticles } from './Articles.util';

const Articles = ({ articles, loading }) => {
  const { formatMessage } = useIntl();

  const { api, siteLang: language } = useApp();

  const [handleGetMoreArticles] = useFetchArticles(articles);

  const [hideButton, setHideButton] = React.useState(false);

  React.useEffect(() => {
    pageView();
  }, []);

  React.useEffect(() => {
    const checkForMoreArticles = async () => {
      const total = await api.count('articles', { language });
      const hasMore = total.data > articles.length;
      return setHideButton(!hasMore);
    };

    checkForMoreArticles();
  }, [api, articles, language]);

  return (
    <Layout className="articles-view" showHeader showFooter testID="Articles">
      <div className="articles-content">
        {loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <div className="articles-container">
            <div className="articles-title">
              <h1>{formatMessage({ id: 'articlesPageTitle' })}</h1>
              <span>{formatMessage({ id: 'articlesPageSubtitle' })}</span>
            </div>
            <Grid container spacing={4}>
              {articles?.map((item, i) => (
                <Grid
                  className="articles-item"
                  key={`articles_item_${i}`}
                  item
                  md={4}
                  sm={6}
                  xs={12}
                >
                  <ScrollAnimation
                    animateOnce={true}
                    animateIn="fadeIn"
                    delay={300}
                  >
                    <NavLink
                      to={{
                        pathname: `/article/${item.id}`,
                        state: { item: item },
                      }}
                    >
                      <div className="articles-card">
                        <img src={assetUrl(item?.cover, 'medium')} alt="" />
                      </div>
                      <div>
                        <h3>{item.title}</h3>
                        <span>{item.subtitle}</span>
                      </div>
                    </NavLink>
                  </ScrollAnimation>
                </Grid>
              ))}
            </Grid>
            {!hideButton ? (
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeIn"
                delay={300}
              >
                <div className="see-more">
                  <Button fullWidth onClick={handleGetMoreArticles}>
                    {formatMessage({ id: 'seeMoreButton' })}
                  </Button>
                </div>
              </ScrollAnimation>
            ) : (
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeIn"
                delay={300}
              >
                <p>{formatMessage({ id: 'messageSawAllArticles' })}</p>
              </ScrollAnimation>
            )}
          </div>
        )}
      </div>

      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk type="small" />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

Articles.propTypes = {
  articles: PropTypes.array,
  loading: PropTypes.bool,
};

const mapStateToProps = state => ({
  articles: state.articles.data,
  loading: state.articles.loading,
});

export default connect(mapStateToProps)(Articles);
