import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useLocation } from 'react-router-dom';

// providers
import { useApp } from '../../providers/App';

const ToggleLanguage = ({ testID, open, siteMapToggle }) => {
  const location = useLocation();
  const path = location.pathname;
  const { siteLang, setSiteLang } = useApp();

  const onToggleSiteLang = () => {
    setSiteLang(siteLang === 'pt-BR' ? 'en-US' : 'pt-BR');
  };

  return (
    <div data-testid={`${testID}_Content`} className="toggle-language">
      <label
        className={cls(
          'switch',
          { reverse: path !== '/' },
          { open },
          { siteMapToggle },
        )}
      >
        <input
          onChange={onToggleSiteLang}
          className="switch-checkbox"
          checked={siteLang === 'en-US'}
          type="checkbox"
          data-testid={`${testID}_Checkbox`}
        />
        <div
          className={cls(
            'switch-button',
            { reverse: path !== '/' },
            { open },
            { siteMapToggle },
          )}
        />
        <div className="switch-labels">
          <span
            className={cls(
              { reverse: path !== '/' },
              { open },
              { siteMapToggle },
              { portuguese: siteLang === 'pt-BR' },
            )}
          >
            PT
          </span>
          <span
            className={cls(
              { reverse: path !== '/' },
              { open },
              { siteMapToggle },
              { english: siteLang === 'en-US' },
            )}
          >
            EN
          </span>
        </div>
      </label>
    </div>
  );
};

ToggleLanguage.defaultProps = {
  testID: 'ToggleLanguage',
};

ToggleLanguage.propTypes = {
  open: PropTypes.bool,
  siteMapToggle: PropTypes.bool,
  testID: PropTypes.string.isRequired,
};

export default ToggleLanguage;
