import React from 'react';
import { useDispatch } from 'react-redux';

import { useApp } from '../../providers';

import { CasesActions } from '../../store';

export const useFetchCases = (cases, limit = 6) => {
  const dispatch = useDispatch();
  const { api, siteLang: language } = useApp();

  React.useEffect(() => {
    dispatch(CasesActions.getCases(api, { page: 1, limit, language }));
  }, [api, dispatch, limit, language]);

  const getMoreCases = () => {
    const total = cases?.length || 0;
    dispatch(
      CasesActions.getMoreCases(api, {
        page: Math.floor(total / limit) + 1,
        limit,
        language,
      }),
    );
  };

  return [getMoreCases];
};
