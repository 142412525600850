import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

const Hover = ({ title, subtitle, type }) => {
  return (
    <div className={cls('data', type)}>
      <h1 className={cls('data-title', type)}>{title}</h1>
      <h6 className={cls('data-subtitle', type)}>{subtitle}</h6>
    </div>
  );
};

Hover.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  small: PropTypes.bool,
  type: PropTypes.oneOf(['articles', 'articlesSmall', 'carouselHome']),
};

export default Hover;
