import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import PropTypes from 'prop-types';

const ServiceCardHomeMobile = ({ title, actionLink, img }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const serviceRef = useRef();
  const imgRef = useRef();

  const handleHover = () => {
    serviceRef.current.addEventListener('mouseenter', () => {
      setIsHovered(true);

      serviceRef.current.classList.add('service-card-container-active');

      imgRef.current && imgRef.current.classList.add('service-card-img-active');
    });

    serviceRef.current.addEventListener('mouseleave', () => {
      setIsHovered(false);

      serviceRef.current.classList.remove('service-card-container-active');

      imgRef.current &&
        imgRef.current.classList.remove('service-card-img-active');
    });
  };

  const toggleCard = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    handleHover();
  }, []);

  useEffect(() => {
    if (isActive) {
      serviceRef.current.classList.add('active');
    } else {
      serviceRef.current.classList.remove('active');
    }
  }, [isActive]);

  return (
    <div
      onClick={() => {
        location.href = actionLink;
      }}
      ref={serviceRef}
      className="service-card-home-mobile-container"
    >
      <Header
        title={title}
        isHovered={isHovered}
        isActive={isActive}
        toggleCard={toggleCard}
      />
      {!isActive &&
        React.cloneElement(img, {
          ref: imgRef,
          className: 'service-card-mobile-img',
        })}
    </div>
  );
};

ServiceCardHomeMobile.propTypes = {
  title: PropTypes.string,
  img: PropTypes.node,
  actionLink: PropTypes.string,
};

export default ServiceCardHomeMobile;
