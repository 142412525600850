import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ReactComponent as SvgFacebook } from '../../assets/images/icons/ic_facebook.svg';
import { ReactComponent as SvgLinkedIn } from '../../assets/images/icons/ic_linkedin.svg';
import { ReactComponent as SvgInstagram } from '../../assets/images/icon_instagram.svg';
import { ReactComponent as SvgYoutube } from '../../assets/images/icon_youtube.svg';
import SvgGreatPlace from '../../assets/images/icons/ic_selo-gptw.svg';
import CuboPartnerLogo from '../../assets/images/icons/cubo-partner.png';

import LanguageDropdown from '../LanguageDropdown';

const SiteMap = ({ testID }) => {
  const { formatMessage } = useIntl();

  const website = [
    { name: formatMessage({ id: 'siteMapCol1Content1' }), path: '/services' },
    { name: formatMessage({ id: 'siteMapCol1Content2' }), path: '/cases' },
    { name: formatMessage({ id: 'siteMapCol1Content3' }), path: '/about' },
    { name: formatMessage({ id: 'siteMapCol1Content4' }), path: '/contact' },
    { name: formatMessage({ id: 'siteMapCol1Content5' }), path: '/careers' },
    { name: formatMessage({ id: 'siteMapCol1Content6' }), path: '/articles' },
  ];

  const offices = [
    {
      name: formatMessage({ id: 'siteMapCol2Content1' }),
      path: '',
    },
    {
      name: formatMessage({ id: 'siteMapCol2Content2' }),
      path: '',
    },
  ];

  const useful = [
    {
      name: formatMessage({ id: 'siteMapCol3Content2' }),
      path: '/privacy-police',
    },
  ];

  return (
    <div className="sitemap">
      <div
        data-testid={`${testID}_SiteMap`}
        className={cls('content-site-map')}
      >
        <div
          data-testid={`${testID}_SiteMap_Menu`}
          className={cls('menu-content')}
        >
          <div data-testid={`${testID}_MapList`} className="title-site-map">
            <p className="section-title">
              {formatMessage({ id: 'siteMapCol1Title' })}
            </p>
            {website.map(item => (
              <Link key={item.name} to={item.path}>
                <p>{item.name}</p>
              </Link>
            ))}
          </div>
          <div data-testid={`${testID}_MapList`} className="title-site-map">
            <p className="section-title">
              {formatMessage({ id: 'siteMapCol2Title' })}
            </p>
            {offices.map(item => (
              <p className={'remote-offices'} key={item.name}>
                {item.name}
              </p>
            ))}
          </div>
          <div data-testid={`${testID}_MapList`} className="title-site-map">
            <p className={cls('section-title', 'privacyPolice')}>
              {formatMessage({ id: 'siteMapCol3Title' })}
            </p>
            {useful.map(item => (
              <Link key={item.name} to={item.path}>
                <p className={cls('privacyPolice')}>{item.name}</p>
              </Link>
            ))}
          </div>
        </div>

        <div className="icons">
          <div className="icons-content">
            <div className="sitemap-icons first">
              <a
                href="https://www.linkedin.com/company/bycoders-tecnologia/"
                target="_black"
              >
                <SvgLinkedIn className="icon-svg" />
              </a>
            </div>
            <div className="sitemap-icons first">
              <a href="https://www.facebook.com/bycodersTec/" target="_black">
                <SvgFacebook className="icon-svg" />
              </a>
            </div>
            <div className="sitemap-icons first">
              <a href="https://www.instagram.com/bycoders_/" target="_black">
                <SvgInstagram className="icon-svg" />
              </a>
            </div>
            <div className="sitemap-icons">
              <a
                href="https://www.youtube.com/channel/UCJoRus1OxyAXMHDTeByRl5w/featured"
                target="_black"
              >
                <SvgYoutube className="icon-svg" />
              </a>
            </div>
          </div>
          <div className="seals">
            <div>
              <img src={CuboPartnerLogo} alt="" />
            </div>
            <div>
              <img src={SvgGreatPlace} alt="" />
            </div>
          </div>
        </div>
        <div className="toggle-lang">
          <LanguageDropdown position="top" type="secondary" />
        </div>
      </div>
    </div>
  );
};

SiteMap.propTypes = {
  testID: PropTypes.string.isRequired,
};
SiteMap.defaultProps = {
  testID: 'SiteMap',
};
export default SiteMap;
