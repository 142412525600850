import React from 'react';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';

// components
import { Button, Layout, Loader, ReadyToTalk, SiteMap } from '../../components';

// others
import { assetUrl } from '../../helpers/Strapi.helper';
import { useFetchCases } from './Cases.util';

import { useApp } from '../../providers';
import { pageView } from '../../utils/Mautic.util';

const Cases = ({ cases, loading, testID }) => {
  const [handleGetMoreCases] = useFetchCases(cases);
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    pageView();
  }, []);

  const { api, siteLang: language } = useApp();
  const [hideButton, setHideButton] = React.useState(false);

  React.useEffect(() => {
    const checkForMoreCases = async () => {
      const total = await api.count('cases', { language });
      const hasMore = total.data > cases.length;
      return setHideButton(!hasMore);
    };

    checkForMoreCases();
  }, [api, cases, language]);

  return (
    <Layout showHeader showFooter testID="Cases">
      <div className="cases-content" data-testid={`${testID}_Container`}>
        {loading ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          <div className="cases-container">
            <div className="cases-title">
              <h1>{formatMessage({ id: 'casesPageTitle' })}</h1>
              <span>{formatMessage({ id: 'casesPageSubtitle' })}</span>
            </div>
            <Grid container spacing={4}>
              {cases.map((item, i) => (
                <Grid key={`cases_item_${i}`} item md={4} sm={6} xs={12}>
                  <ScrollAnimation
                    animateOnce={true}
                    animateIn="fadeIn"
                    delay={300}
                  >
                    <NavLink
                      to={{
                        pathname: `/case/${item.id}`,
                        state: { item: item },
                      }}
                    >
                      <div className="cases-list">
                        <div className="cases-card">
                          <img src={assetUrl(item?.cover, 'medium')} alt="" />
                        </div>
                        <div>
                          <h3>{item.title}</h3>
                          <span>{item.subtitle}</span>
                        </div>
                      </div>
                    </NavLink>
                  </ScrollAnimation>
                </Grid>
              ))}
            </Grid>
            {!hideButton ? (
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeIn"
                delay={300}
              >
                <div className="see-more">
                  <Button fullWidth onClick={handleGetMoreCases}>
                    {formatMessage({ id: 'seeMoreButton' })}
                  </Button>
                </div>
              </ScrollAnimation>
            ) : (
              <ScrollAnimation
                animateOnce={true}
                animateIn="fadeIn"
                delay={300}
              >
                <p>{formatMessage({ id: 'messageSawAllCases' })}</p>
              </ScrollAnimation>
            )}
          </div>
        )}
      </div>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk type="small" />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

Cases.propTypes = {
  cases: PropTypes.array,
  loading: PropTypes.bool,
  testID: PropTypes.string.isRequired,
};

Cases.defaultProps = {
  cases: [],
  testID: 'Cases',
};

const mapStateToProps = state => ({
  cases: state.cases.data,
  loading: state.cases.loading,
});

export default connect(mapStateToProps)(Cases);
