import { careersActions } from './careers.store';

export const getJobVacancies = (api, { page, limit, language } = {}) => {
  return async dispatch => {
    try {
      dispatch(careersActions.getJobVacancies());
      const response = await api.getJobVacancies({ page, limit, language });
      const jobVacancies = Array.isArray(response?.data) ? response.data : [];
      dispatch(careersActions.getJobVacanciesSuccess({ jobVacancies }));
    } catch (error) {
      dispatch(
        careersActions.getJobVacanciesFail({
          error: error?.message || 'Falha ao consultar vagas',
        }),
      );
    }
  };
};
