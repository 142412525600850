import { createSlice } from '@reduxjs/toolkit';

import { uniqueById } from '../../utils/Filter.util';

const articlesStore = createSlice({
  name: 'articlesStore',
  initialState: {
    data: [],
    error: '',
    loading: false,
    loadingMore: false,
    prioritizedData: [],
    prioritizedLoading: true,
  },
  reducers: {
    getArticles: state => ({
      ...state,
      loading: true,
    }),
    getArticlesSuccess: (state, { payload }) => ({
      ...state,
      data: payload.articles,
      loading: false,
    }),
    getArticlesFail: (state, { payload }) => ({
      ...state,
      error: payload.error,
      loading: false,
    }),

    getMoreArticles: state => ({
      ...state,
      loadingMore: true,
    }),
    getMoreArticlesSuccess: (state, { payload }) => ({
      ...state,
      data: [...(state.data || []), ...(payload.articles || [])].filter(
        uniqueById,
      ),
      loadingMore: false,
    }),
    getMoreArticlesFail: (state, { payload }) => ({
      ...state,
      error: payload.error,
      loadingMore: false,
    }),

    getPrioritizedArticles: state => ({
      ...state,
      prioritizedLoading: true,
    }),
    getPrioritizedArticlesSuccess: (state, { payload }) => ({
      ...state,
      prioritizedData: payload.articles,
      prioritizedLoading: false,
    }),
    getPrioritizedArticlesFail: (state, { payload }) => ({
      ...state,
      error: payload.error,
      prioritizedLoading: false,
    }),
  },
});

export const articlesActions = articlesStore.actions;

export const articlesReducer = articlesStore.reducer;
