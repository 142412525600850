export const getData = ({ data }) => data;

export const getFirst = ({ data }) => {
  if (data?.length) {
    return data[0];
  }
  return undefined;
};

export const sanitizeParams = params =>
  Object.entries(params).reduce((acc, [key, value]) => {
    if (value || value === false) acc[key] = value;
    return acc;
  }, {});
