import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import React, { useState } from 'react';
import { Collapse } from 'react-collapse';
import { useIntl } from 'react-intl';

const CollapseList = ({ defaultList, setList, setFiltered }) => {
  const [option, setOption] = useState('');
  const { formatMessage } = useIntl();

  let countrys = defaultList.map(x => x.location);
  countrys = Array.from(new Set(countrys.map(a => a))).map(x => {
    return countrys.find(a => a === x);
  });

  let places = defaultList.map(x => x.state);
  places = Array.from(new Set(places.map(a => a))).map(x => {
    return places.find(a => a === x);
  });

  let areas = defaultList.map(x => x.area.name);
  areas = Array.from(new Set(areas.map(a => a))).map(x => {
    return areas.find(a => a === x);
  });

  let typeContracts = defaultList.map(x => x.typecontract.name);
  typeContracts = Array.from(new Set(typeContracts.map(a => a))).map(x => {
    return typeContracts.find(a => a === x);
  });

  const menus = [
    {
      id: 1,
      name: formatMessage({ id: 'careerPageFilterCountry' }),
      options: countrys,
    },
    {
      id: 2,
      name: formatMessage({ id: 'careerPageFilterPlace' }),
      options: places,
    },
    {
      id: 3,
      name: formatMessage({ id: 'careerPageFilterTypeContract' }),
      options: typeContracts,
    },
    {
      id: 4,
      name: formatMessage({ id: 'careerPageFilterArea' }),
      options: areas,
    },
  ];

  function UpdateList(itemOption, type) {
    let newList = defaultList.filter(x => {
      return (
        (type === 1 && x.location === itemOption) ||
        (type === 2 && x.state === itemOption) ||
        (type === 3 && x.typecontract.name === itemOption) ||
        (type === 4 && x.area.name === itemOption)
      );
    });
    setList(newList);
    setFiltered(true);
  }

  return menus.map((item, index) => (
    <div key={index} className="collapse">
      <div
        className={
          option === item.name ? 'collapse--header--active' : 'collapse--header'
        }
        onClick={() => setOption(option === item.name ? '' : item.name)}
      >
        <p className="black">{item.name}</p>
        {option === item.name ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
      <Collapse isOpened={option === item.name}>
        <form>
          {item.options.map((itemOption, i) => (
            <label key={i}>
              <input
                type="radio"
                id={item.name + i.toString()}
                name={item.name}
                onChange={() => UpdateList(itemOption, item.id)}
              />
              <span>{itemOption}</span>
            </label>
          ))}
        </form>
      </Collapse>
    </div>
  ));
};

export default CollapseList;
