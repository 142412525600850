import React, { useEffect } from 'react';
import { ServiceCard } from '../../../components';
import { ReactComponent as ServiceCardLogo1 } from '../../../assets/images/service-card-logo-1.svg';
import { ReactComponent as ServiceCardLogo2 } from '../../../assets/images/service-card-logo-2.svg';
import { ReactComponent as ServiceCardLogo3 } from '../../../assets/images/service-card-logo-3.svg';
import { ReactComponent as ServiceCardLogo4 } from '../../../assets/images/service-card-logo-4.svg';
import { ReactComponent as ServiceCardLogo5 } from '../../../assets/images/service-card-logo-5.svg';
import { ReactComponent as ServiceCardLogo6 } from '../../../assets/images/service-card-logo-6.svg';
import { ReactComponent as Velsis } from '../../../assets/images/velsis.svg';
import { ReactComponent as KPMG } from '../../../assets/images/kpmg-2.svg';
import { ReactComponent as Embarca } from '../../../assets/images/embarca-ai.svg';
import { ReactComponent as Paypal } from '../../../assets/images/paypal-2.svg';
import { ReactComponent as MercadoBitcoin } from '../../../assets/images/mercado-bitcoin.svg';
import { useIntl } from 'react-intl';

import Title from './Title';
import Footer from './Footer';

const ServiceSection = () => {
  const { formatMessage } = useIntl();
  const activeService = location.search
    .replace('?', '')
    .split('&')
    .find(e => e.includes('active'))
    ?.split('=')[1];

  useEffect(() => {
    const active = location.search?.split('?')[1]?.split('=')[1];

    if (active) {
      const serviceElement = document.getElementById(`service-card-${active}`);

      if (serviceElement) {
        setTimeout(() => {
          const scrollToService = () => {
            serviceElement.scrollIntoView({
              behavior: 'smooth',
            });
          };

          requestAnimationFrame(scrollToService);
        }, 500);
      }
    }
  }, []);

  return (
    <div className="service-section-container">
      <div className="service-section-title-cards-footer">
        <Title />
        <div className="service-section-card-wrapper">
          <ServiceCard
            id="service-card-1"
            title={formatMessage({ id: 'ServiceDataTitle' })}
            text={formatMessage({ id: 'ServiceDataText' })}
            actionLink={'/cases'}
            activeByHover={location.pathname.includes('services2')}
            isDefaultActive={activeService === '1'}
            clientimg={<Velsis />}
            img={<ServiceCardLogo1 />}
          />
          <ServiceCard
            id="service-card-2"
            title={formatMessage({ id: 'ServiceSoftwareDevelopmentTitle' })}
            text={formatMessage({ id: 'ServiceSoftwareDevelopmentText' })}
            actionLink={`/case/25`}
            activeByHover={location.pathname.includes('services2')}
            isDefaultActive={activeService === '2'}
            clientimg={<KPMG />}
            img={<ServiceCardLogo2 />}
          />
          <ServiceCard
            id="service-card-3"
            title={formatMessage({ id: 'ServiceTeamAsAServiceTitle' })}
            text={formatMessage({ id: 'ServiceTeamAsAServiceText' })}
            actionLink={'/case/23'}
            activeByHover={location.pathname.includes('services2')}
            isDefaultActive={activeService === '3'}
            clientimg={<Embarca />}
            img={<ServiceCardLogo3 />}
          />
          <ServiceCard
            id="service-card-4"
            title={formatMessage({ id: 'ServiceDigitalTransformationTitle' })}
            text={formatMessage({ id: 'ServiceDigitalTransformationText' })}
            actionLink={'/case/26'}
            activeByHover={location.pathname.includes('services2')}
            isDefaultActive={activeService === '4'}
            clientimg={<Paypal />}
            img={<ServiceCardLogo4 />}
          />
          <ServiceCard
            id="service-card-5"
            title={formatMessage({ id: 'ServiceSustentationAndSuportTitle' })}
            text={formatMessage({ id: 'ServiceSustentationAndSuportText' })}
            actionLink={'/cases'}
            activeByHover={location.pathname.includes('services2')}
            isDefaultActive={activeService === '5'}
            clientimg={<MercadoBitcoin />}
            img={<ServiceCardLogo5 />}
          />
          <ServiceCard
            id="service-card-6"
            title={formatMessage({ id: 'ServiceDesignUIUXTitle' })}
            text={formatMessage({ id: 'ServiceDesignUIUXText' })}
            actionLink={'/case/23'}
            activeByHover={location.pathname.includes('services2')}
            isDefaultActive={activeService === '6'}
            clientimg={<Embarca />}
            img={<ServiceCardLogo6 />}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ServiceSection;
