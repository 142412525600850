/**
 * Create a unique filter function by field name.
 *
 * @param {String} field
 * @returns {Function} filter function
 */
export const uniqueBy = field => (item, i, arr) =>
  arr.findIndex(_ => _[field] === item[field], i) === i;

export const uniqueById = uniqueBy('id');
