import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

// components
import Container from '../Container';
import Footer from '../Footer';
import Header from '../Header';

const Layout = ({
  children,
  className,
  scrollable,
  showFooter,
  showHeader,
  testID,
}) => (
  <div className={cls('layout', className)} data-testid={`${testID}_Layout`}>
    {showHeader && <Header />}
    <Container scrollable={scrollable} testID={`${testID}_Layout`}>
      {/* {showNavigation && <AppNav />} */}
      {children}
      {showFooter && <Footer />}
    </Container>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollable: PropTypes.bool,
  showFooter: PropTypes.bool,
  showHeader: PropTypes.bool,
  testID: PropTypes.string.isRequired,
};

Layout.defaultProps = {
  testID: 'Layout',
};

export default Layout;
