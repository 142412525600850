import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Loader = ({ size }) => {
  return (
    <div className={cls('loader', size)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

Loader.defaultProps = {
  size: 'medium',
};

export default Loader;
