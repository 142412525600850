import { articlesActions } from './articles.store';

export const getArticles = (api, { page, limit, language } = {}) => {
  return async dispatch => {
    try {
      dispatch(articlesActions.getArticles());
      const response = await api.getArticles({ page, limit, language });
      const articles = Array.isArray(response?.data) ? response.data : [];
      dispatch(articlesActions.getArticlesSuccess({ articles }));
    } catch (error) {
      dispatch(
        articlesActions.getArticlesFail({
          error: error?.message || 'Falha ao consultar artigos',
        }),
      );
    }
  };
};

export const getPrioritizedArticles = (api, { page, limit, language } = {}) => {
  return async dispatch => {
    try {
      dispatch(articlesActions.getPrioritizedArticles());
      const response = await api.getPrioritizedArticles({
        page,
        limit,
        language,
      });
      const articles = Array.isArray(response?.data) ? response.data : [];
      dispatch(articlesActions.getPrioritizedArticlesSuccess({ articles }));
    } catch (error) {
      dispatch(
        articlesActions.getPrioritizedArticlesFail({
          error: error?.message || 'Falha ao consultar artigos',
        }),
      );
    }
  };
};

export const getMoreArticles = (api, { page, limit, language } = {}) => {
  return async dispatch => {
    try {
      dispatch(articlesActions.getMoreArticles());
      const response = await api.getArticles({ page, limit, language });
      const articles = response.data;
      dispatch(articlesActions.getMoreArticlesSuccess({ articles }));
    } catch (error) {
      dispatch(
        articlesActions.getMoreArticlesFail({
          error: error?.message || 'Falha ao consultar artigos',
        }),
      );
    }
  };
};
