import React from 'react';
import { useIntl } from 'react-intl';
import ScrollAnimation from 'react-animate-on-scroll';

import { Layout, ReadyToTalk, SiteMap } from '../../components';

import { ReactComponent as SvgFacebook } from '../../assets/images/icons/ic_facebook.svg';
import { ReactComponent as SvgLinkedIn } from '../../assets/images/icons/ic_linkedin.svg';
import { ReactComponent as SvgInstagram } from '../../assets/images/icon_instagram.svg';
import { ReactComponent as SvgYoutube } from '../../assets/images/icon_youtube.svg';
import ceo1 from '../../assets/images/ceo1.jpg';
import ceo2 from '../../assets/images/ceo2.jpg';

import { pageView } from '../../utils/Mautic.util';

const About = () => {
  const { formatMessage } = useIntl();
  React.useEffect(() => {
    pageView();
  }, []);

  return (
    <Layout className="about" showFooter showHeader testID="About">
      <div className="about-header">
        <div className="about-header-top">
          <div className="header-content">
            <span>{formatMessage({ id: 'aboutPageSocialMedia' })}</span>
            <div className="icons">
              <div className="about-icons first">
                <a
                  href="https://www.linkedin.com/company/bycoders-tecnologia/"
                  target="_black"
                >
                  <SvgLinkedIn className="icon-svg" />
                </a>
              </div>
              <div className="about-icons first">
                <a href="https://www.facebook.com/bycodersTec/" target="_black">
                  <SvgFacebook className="icon-svg" />
                </a>
              </div>
              <div className="about-icons first">
                <a href="https://www.instagram.com/bycoders_/" target="_black">
                  <SvgInstagram className="icon-svg" />
                </a>
              </div>
              <div className="about-icons">
                <a
                  href="https://www.youtube.com/channel/UCJoRus1OxyAXMHDTeByRl5w/featured"
                  target="_black"
                >
                  <SvgYoutube className="icon-svg" />
                </a>
              </div>
            </div>
          </div>

          <div className="header-title">
            <span>{formatMessage({ id: 'aboutPageSubtitle' })}</span>
            <h2>{formatMessage({ id: 'aboutPageTitle' })}</h2>
          </div>
        </div>

        <div className="header-description">
          <span>{formatMessage({ id: 'aboutPageContent1' })}</span>
          <span>{formatMessage({ id: 'aboutPageContent2' })}</span>
        </div>

        <div className="header-content-title">
          <h3>{formatMessage({ id: 'aboutPagePurposeTitle' })}</h3>
          <p>{formatMessage({ id: 'aboutPagePurposeContent' })}</p>
        </div>
        <div className="header-content-title">
          <h3>{formatMessage({ id: 'aboutPageVisionTitle' })}</h3>
          <p>{formatMessage({ id: 'aboutPageVisionContent' })}</p>
        </div>

        <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
          <div className="about-content">
            <div className="about-content-title">
              <h3>{formatMessage({ id: 'meetFounder' })}</h3>
            </div>
            <div className="about-content-top">
              <div className="content-top">
                <div className="about-content">
                  <div className="images">
                    <img src={ceo1} alt="" />
                  </div>
                  <div className="infos">
                    <span>CEO-founder</span>
                    <h2>Vanildo Prates</h2>
                    <div className="icons">
                      <div className="about-icons first">
                        <a
                          href="https://www.linkedin.com/in/vanildo-prates/"
                          target="_black"
                        >
                          <SvgLinkedIn className="icon-svg" />
                        </a>
                      </div>
                    </div>
                    <span className="infos-founder">
                      {formatMessage({ id: 'homeCeoBio1' })}
                      {formatMessage({ id: 'homeCeoBio2' })}
                      {formatMessage({ id: 'homeCeoBio3' })}
                    </span>
                  </div>
                </div>
                <div className="about-content">
                  <div className="images">
                    <img src={ceo2} alt="" />
                  </div>
                  <div className="infos">
                    <span>Coo-Co-founder</span>
                    <h2>Marcelo Pedreira</h2>
                    <div className="icons">
                      <div className="about-icons first">
                        <a
                          href="https://www.linkedin.com/in/marcelofaleiropedreira/"
                          target="_black"
                        >
                          <SvgLinkedIn className="icon-svg" />
                        </a>
                      </div>
                    </div>
                    <span className="infos-founder">
                      {formatMessage({ id: 'homeCooBio1' })}
                      {formatMessage({ id: 'homeCooBio2' })}
                      {formatMessage({ id: 'homeCooBio3' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </div>

      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk type="small" />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

export default About;
