import { APPLE, BASIC, DARCULA } from './themes';

export const THEMES = {
  APPLE: 'apple',
  BASIC: 'basic',
  DARCULA: 'darcula',
};

export const getTheme = themeName => {
  switch (themeName) {
    case THEMES.APPLE:
      return APPLE;
    case THEMES.DARCULA:
      return DARCULA;
    default:
      return BASIC;
  }
};
