import React from 'react';
import ServiceCardHomeDesktop from './ServiceCardHomeDesktop';
import ServiceCardHomeMobile from './ServiceCardHomeMobile';
import PropTypes from 'prop-types';

const ServiceCardHome = ({ title, actionLink, img }) => {
  return (
    <>
      <ServiceCardHomeDesktop title={title} actionLink={actionLink} img={img} />
      <ServiceCardHomeMobile title={title} actionLink={actionLink} img={img} />
    </>
  );
};

ServiceCardHome.propTypes = {
  title: PropTypes.string,
  actionLink: PropTypes.string,
  img: PropTypes.node,
};

export default ServiceCardHome;
