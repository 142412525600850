import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Markdown from 'markdown-to-jsx';
import ScrollAnimation from 'react-animate-on-scroll';
import { useIntl } from 'react-intl';

import {
  Button,
  CarouselSlider,
  Layout,
  ReadyToTalk,
  SiteMap,
  SocialButtons,
} from '../../components';

import { assetUrl } from '../../helpers/Strapi.helper';
import { useApp } from '../../providers';
import { pageView } from '../../utils/Mautic.util';

const ArticleInside = ({ articles }) => {
  const history = useHistory();
  const params = useParams();
  const { api, siteLang: language } = useApp();
  const auxLanguage = language === 'pt-BR' ? 'pt-BR' : 'en';

  React.useEffect(() => {
    pageView();
  }, []);

  const { formatMessage } = useIntl();

  const [data, setData] = React.useState({
    article: null,
    error: null,
    isFetching: true,
  });

  const changeArticleLanguage = useCallback(() => {
    if (
      data?.article?.locale === auxLanguage ||
      !data.article ||
      data.isFetching === true
    )
      return;

    if (data?.article?.localizations?.length === 0) {
      history.replace(`/articles`);

      return;
    }

    const _article = data?.article?.localizations[0].id;

    history.replace(`/article/${_article}`);
  }, [data, history, auxLanguage]);

  React.useEffect(() => {
    changeArticleLanguage();
  }, [changeArticleLanguage]);

  React.useEffect(() => {
    if (!params?.id) return;

    const fetchArticle = async articleId => {
      try {
        const { data: article } = await api.getArticle(articleId);
        setData({ article, isFetching: false });
      } catch (error) {
        setData({
          error: error?.message || 'Falha ao consultar artigo.',
          isFetching: false,
        });
      }
    };

    fetchArticle(params.id);
  }, [params, api]);

  const othersArticles = React.useMemo(
    () =>
      articles?.filter(report => report.id !== data?.article?.id).slice(0, 4),
    [articles, data],
  );

  const handleGoArticles = () => {
    history.push('/articles');
  };

  return (
    <Layout className="article" showFooter showHeader testID="Article">
      <div className="header-top">
        <div
          className="header-top-cover"
          style={{
            backgroundImage: data?.article?.coverTop
              ? `url(${assetUrl(data.article.coverTop)}`
              : 'url(/assets/images/bg_fiber.jpg)',
            opacity: data?.isFetching ? 0 : 1,
          }}
        ></div>
      </div>

      <div className="all-cases">
        <div className="header">
          <div className="header-back" onClick={handleGoArticles}>
            <Button icon="ArrowBack" type="rounded" />{' '}
            <span>{formatMessage({ id: 'pageArticleButtonBack' })}</span>
          </div>

          <div className="header-title">
            <h5>{formatMessage({ id: 'pageArticleTitle' })}</h5>
            <h1>{data?.article?.title}</h1>
            <div className="header-title-icons">
              {data?.article?.content && <SocialButtons data={data.article} />}
            </div>
          </div>

          {data?.article?.resume ? (
            <div className="header-content">
              <p>{data?.article?.resume}</p>
            </div>
          ) : (
            <div className="header-content">
              <p> {data?.article?.subtitle}</p>
            </div>
          )}
          {data?.article?.content && (
            <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
              <div className="header-paragraph-first">
                <Markdown>{data.article.content}</Markdown>
              </div>
            </ScrollAnimation>
          )}
        </div>

        <div className="horizontal-divider"></div>
      </div>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <div className="other-articles">
          <CarouselSlider
            data={othersArticles}
            type="articlesHomeLarge"
            subtitle={formatMessage({ id: 'pageArticleCarouselSubtitle' })}
            title={formatMessage({ id: 'pageArticleCarouselTitle' })}
          />
        </div>
      </ScrollAnimation>

      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <ReadyToTalk type="small" />
      </ScrollAnimation>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" delay={300}>
        <SiteMap />
      </ScrollAnimation>
    </Layout>
  );
};

ArticleInside.propTypes = {
  articles: PropTypes.array,
};

const mapStateToProps = state => ({
  articles: state.articles.data,
});

export default connect(mapStateToProps)(ArticleInside);
