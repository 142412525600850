import { connect } from 'react-redux';

import Home from './Home';

const mapStateToProps = state => ({
  articles: state.articles.prioritizedData,
  cases: state.cases.data,
  clients: state.clients.data,
});

export default connect(mapStateToProps)(Home);
