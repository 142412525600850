import { casesActions } from './cases.store';

export const getCases = (api, { page, limit, language } = {}) => {
  return async dispatch => {
    try {
      dispatch(casesActions.getCases());
      const response = await api.getCases({ page, limit, language });
      const cases = Array.isArray(response?.data) ? response.data : [];
      dispatch(casesActions.getCasesSuccess({ cases }));
    } catch (error) {
      dispatch(
        casesActions.getCasesFail({
          error: error?.message || 'Falha ao consultar casos',
        }),
      );
    }
  };
};

export const getMoreCases = (api, { page, limit, language } = {}) => {
  return async dispatch => {
    try {
      dispatch(casesActions.getMoreCases());
      const response = await api.getCases({ page, limit, language });
      const cases = response.data;
      dispatch(casesActions.getMoreCasesSuccess({ cases }));
    } catch (error) {
      dispatch(
        casesActions.getMoreCasesFail({
          error: error?.message || 'Falha ao consultar casos',
        }),
      );
    }
  };
};
