import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Layout, Button } from '../../components';

import { ReactComponent as SvgNotFound } from '../../assets/images/icons/ic_not_found.svg';

const NotFound = () => {
  const { formatMessage } = useIntl();

  return (
    <Layout showHeader testID="NotFound">
      <div className="not-found">
        <div className="not-found-svg">
          <SvgNotFound />
        </div>
        <p>{formatMessage({ id: 'msgNotFound' })}</p>
        <Link to="/">
          <div className="not-found-button">
            <Button fullWidth type="notFound">
              {formatMessage({ id: 'buttonNotFound' })}
            </Button>
          </div>
        </Link>
      </div>
    </Layout>
  );
};

export default NotFound;
