import React, { useEffect, useRef } from 'react';
import { ReactComponent as Close } from '../../../../assets/images/close-2.svg';
import PropTypes from 'prop-types';

const Header = ({ activeByHover, title, isHovered, isActive, disableCard }) => {
  const underScoreTitleRef = useRef();
  const titleRef = useRef();

  useEffect(() => {
    if (isHovered) {
      titleRef.current.classList.add('hover');
      underScoreTitleRef.current.classList.add('hover');
    } else {
      titleRef.current.classList.remove('hover');
      underScoreTitleRef.current.classList.remove('hover');
    }
  }, [isHovered]);

  useEffect(() => {
    if (isActive) {
      titleRef.current.classList.add('active');
      underScoreTitleRef.current.classList.add(
        'service-card-title-underscore-active',
      );
    } else {
      titleRef.current.classList.remove('active');
      underScoreTitleRef.current.classList.remove(
        'service-card-title-underscore-active',
      );
    }
  }, [isActive]);

  return (
    <div className="service-card-header">
      <div className="service-card-title-wrapper">
        <div ref={titleRef} className="service-card-title">
          {title}
        </div>
        <div
          ref={underScoreTitleRef}
          className="service-card-title-underscore"
        />
      </div>
      {isActive && !activeByHover && (
        <div
          onClick={e => {
            e.stopPropagation();
            disableCard();
          }}
          className="service-card-header-close"
        >
          <Close />
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  activeByHover: PropTypes.bool,
  title: PropTypes.string,
  isHovered: PropTypes.bool,
  isActive: PropTypes.bool,
  disableCard: PropTypes.func,
};

export default Header;
