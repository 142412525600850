import React, { useEffect, useState } from 'react';
import BrazilFlag from '../../assets/images/icons/brazil-svgrepo-com.svg';
import EnglishFlag from '../../assets/images/icons/united-states-svgrepo-com.svg';
import LanguageDropdownItem from './LanguageDropdownItem';
import LanguageSelected from './LanguageSelected';

import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

const LanguageDropdown = ({ position = 'default', type = 'primary' }) => {
  const dropdownId = uuidv4();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.addEventListener('click', e => {
      const targetClasses = e.target.classList;
      if (
        !targetClasses.contains('dropdown-item') &&
        !targetClasses.contains('dropdown-selected') &&
        !targetClasses.contains('dropdown-item-flag') &&
        !targetClasses.contains('dropdown-item-label') &&
        !targetClasses.contains('dropdown-selected-flag') &&
        !targetClasses.contains('dropdown-selected-label')
      ) {
        setIsOpen(false);
      }
    });
  }, []);

  const positionDropdown = () => {
    const containerDropdown = document.getElementById(
      `container-dropdown-${dropdownId}`,
    );

    if (containerDropdown) {
      if (position === 'top') {
        containerDropdown.style.bottom = 0;

        containerDropdown.style.left = 0;
      }

      if (position === 'default') {
        containerDropdown.style.top = 0;

        containerDropdown.style.left = 0;
      }
    }
  };

  const handleDropdown = isOpenParam => {
    positionDropdown();

    setIsOpen(isOpenParam);
  };

  useEffect(() => {
    document.addEventListener('scroll', () => {
      positionDropdown();
    });
  }, [position, isOpen]);

  return (
    <div id={`dropdown-${dropdownId}`} className="dropdown">
      <LanguageSelected
        id={`language-selected-${dropdownId}`}
        isOpen={isOpen}
        handleDropdown={handleDropdown}
        type={type}
      />
      <div
        id={`container-dropdown-${dropdownId}`}
        className="container-dropdown"
      >
        {isOpen && (
          <>
            <LanguageDropdownItem
              flag={BrazilFlag}
              label="PT"
              action="pt-BR"
              setIsOpen={setIsOpen}
            />
            <LanguageDropdownItem
              flag={EnglishFlag}
              label="EN"
              action="en-US"
              setIsOpen={setIsOpen}
            />
          </>
        )}
      </div>
    </div>
  );
};

LanguageDropdown.propTypes = {
  position: PropTypes.string,
  type: PropTypes.string,
};

export default LanguageDropdown;
